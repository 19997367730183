/// <reference types="googlemaps" />

import { loadStripe } from "@stripe/stripe-js";

import { getScript } from "@kikoff/client-utils/src/dom";
import { promiseDelay } from "@kikoff/utils/src/general";
import { raceToSuccess } from "@kikoff/utils/src/promise";

import { track } from "./analytics";

declare global {
  interface Window {
    googleMapsServiceCallback: () => void;
  }
}

const loadService = {
  googleMaps(): Promise<typeof window.google.maps> {
    const promise = new Promise<typeof window.google.maps>((resolve) => {
      window.googleMapsServiceCallback = () => {
        delete window.googleMapsServiceCallback;
        resolve(window.google.maps);
      };
    });
    getScript(
      `https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_MAPS_API_KEY}&libraries=places,geometry&callback=googleMapsServiceCallback`
    );
    return promise;
  },
  async stripe() {
    const errors: string[] = [];
    let client: "stripe" | "manual" | "fallback" = null;
    const stripePromise = (async () => {
      let tries = 0;
      while (tries < 5) {
        try {
          tries++;
          // eslint-disable-next-line
          const stripe = await loadStripe(process.env.STRIPE_PUBLIC_KEY);
          if (!client) client = "stripe";
          return stripe;
        } catch (e) {
          track("Stripe - Failed", { message: `${e?.message}` });
          // Include `undefined` as string if e does not exist
          errors.push(`${e?.message}`);
          // eslint-disable-next-line no-await-in-loop
          await promiseDelay(1000);
        }
      }
      throw new Error("Prevent race completion");
    })();

    const manualPromise = (async () => {
      // Wait 2 seconds before attempting manual load
      await promiseDelay(2000);

      let tries = 0;

      while (tries < 3) {
        try {
          tries++;
          // eslint-disable-next-line
          return await getScript("https://js.stripe.com/v3").then(() => {
            if (client) return;
            if (!window.Stripe)
              throw new Error("stripe resolved without client");

            client = "manual";
            return window.Stripe(process.env.STRIPE_PUBLIC_KEY);
          });
        } catch (e) {
          errors.push(`${e?.message}`);
          // eslint-disable-next-line no-await-in-loop
          await promiseDelay(500);
        }
      }

      throw new Error("Prevent race completion");
    })();

    const fallbackPromise = (async () => {
      // Wait for manual promise before trying fallback for at most 5 seconds
      await Promise.allSettled([manualPromise, promiseDelay(5000)]);

      if (client) return;

      try {
        return await getScript("/stripe.js").then(() => {
          if (client) return;
          client = "fallback";
          return window.Stripe(process.env.STRIPE_PUBLIC_KEY);
        });
      } catch (e) {
        errors.push(`${e?.message}`);
      }
    })();

    const stripe = await raceToSuccess([
      stripePromise,
      manualPromise,
      fallbackPromise,
    ]);

    if (!stripe || errors.length > 0 || client !== "stripe")
      track("Stripe - Failed to Load", {
        error: [...new Set(errors)],
        failedAttempts: errors.length,
        recovered: !!stripe,
        client,
      });

    if (!stripe) throw new Error("Failed to load stripe, please reload.");
    return stripe;
  },
};

const serviceCache: { [key in keyof typeof loadService]?: any } = {};

export function getService<T extends keyof typeof loadService>(
  service: T
): ReturnType<typeof loadService[T]> {
  if (!serviceCache[service]) serviceCache[service] = loadService[service]();

  return serviceCache[service];
}
