import Image from "next/image";
import { FluentResource } from "@fluent/bundle";
import { LocalizationProvider, Localized } from "@fluent/react";

import KLink from "@kikoff/components/src/v1/navigation/KLink";

import Marquee from "@component/lists/marquee";
import { newReactLocalization } from "@util/l10n";

import creditKarma from "./credit_karma.svg";
import nerdwallet from "./nerdwallet.png";

import styles from "./styles.module.scss";

const originalQuotes = [
  {
    name: "Timothy L.",
    quote:
      "I’m a teenager about to turn 19, and of course credit is extremely confusing. Kikoff has helped me get a better understanding of the importance of building credit, while actively getting a better credit score!",
  },
  {
    name: "Desiree F.",
    quote:
      "In as little as 2 months, my credit went from a 545 to almost a 600! I have a 2 year old with  my fiance, so getting our credit up for our family is everything!",
  },
  {
    name: "Nathan C.",
    quote:
      "I’m in the middle of getting my first car loan because my credit went from 0 - 596 in one payment... I was skeptical but now I tell all my friends about it!",
  },
  {
    name: "@seph.selle",
    quote: "0 in July, 700 in December. Y’all did that.",
  },
  {
    name: "@ian_the_1",
    quote:
      "I started with no credit score at all and within one month you guys got it up to 620. Thanks guys!",
  },
  {
    name: "Taeyana T.",
    quote:
      "I started my credit only a month ago, I started off with no credit score at all and a month later my credit score is at 596!",
  },
];

const RESOURCES = {
  en: new FluentResource(`quotes-join = Join over a million people building their credit with Kikoff.
quotes-title = Don’t just take our word for it.
quotes-reviewed-on = As reviewed on
`),
  es: new FluentResource(`quotes-join = Únase a más de un millón de personas construyendo su crédito con Kikoff.
quotes-title = Compruébalo tú mismo.
quotes-reviewed-on = Según lo reseñado en
`),
};

function Quotes() {
  const l10n = newReactLocalization(RESOURCES);

  return (
    <section className={styles["quotes-section"]} data-cy="homepage-quotes">
      <LocalizationProvider l10n={l10n}>
        <header>
          <Localized id="quotes-title">
            Don’t just take our word for it.
          </Localized>
        </header>
        <div className={styles.note}>
          <p>
            <Localized id="quotes-join">
              Join hundreds of thousands building their credit with Kikoff.
            </Localized>
          </p>
          <br />
          <div className="column-center">
            <Localized id="quotes-reviewed-on">As reviewed on</Localized>
            <div className={styles.reviewers}>
              <Image
                src={nerdwallet}
                alt="Nerdwallet"
                width={195}
                height={32}
              />
              <KLink href="https://www.creditkarma.com/personal-loans/i/kikoff-review">
                <Image
                  src={creditKarma}
                  alt="Credit Karma"
                  width={220}
                  height={32}
                />
              </KLink>
            </div>
          </div>
        </div>
        <Marquee className={styles["homepage-marquee"]} draggable>
          {originalQuotes.map(({ name, quote }, i) => {
            return (
              <div className={styles["quote"]} key={i}>
                <div>{quote}</div>
                <div>{name}</div>
              </div>
            );
          })}
        </Marquee>
      </LocalizationProvider>
    </section>
  );
}

export default Quotes;
