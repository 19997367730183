import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Router from "next/router";
import {
  addBusinessDays,
  addMonths,
  differenceInDays,
  startOfDay,
  startOfMonth,
} from "date-fns";
import { minBy, orderBy } from "lodash-es";
import { Tuple } from "record-tuple";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { renderFirstTruthyNode } from "@kikoff/client-utils/src/react";
import KButton from "@kikoff/components/src/v1/buttons/KButton";
import Card from "@kikoff/components/src/v1/cards/Card";
import Alert from "@kikoff/components/src/v1/info/Alert";
import ListTile from "@kikoff/components/src/v1/info/ListTile";
import KLink from "@kikoff/components/src/v1/navigation/KLink";
import useErrorConsumer from "@kikoff/hooks/src/useErrorConsumer";
import { web } from "@kikoff/proto/src/protos";
import { webRPC } from "@kikoff/proto/src/rpc";
import { msIn } from "@kikoff/utils/src/date";
import { serverNow } from "@kikoff/utils/src/number";
import {
  handleFailedStatus,
  handleProtoStatus,
  protoDate,
  protoTime,
} from "@kikoff/utils/src/proto";
import { cls, format, sentenceCase } from "@kikoff/utils/src/string";
import Table from "@kikoff/utils/src/table";

import NegotiationNotCompletedReasons from "@page/dashboard/negotiations/debt/_components/NegotiationNotCompletedReasons";
import { debtNegotiationsEligibleStates } from "@src/constants";
import { useBackendExperiment } from "@src/experiments/context";
import { Popup, PopupGroup, PopupType } from "@src/hooks/usePopups";
import { useOverlaysController } from "@src/overlay";
import { RootState } from "@store";
import { track } from "@util/analytics";

import { createLoadableSelector, thunk } from "../utils";

import { initCreditV2, selectCredit } from "./credit";
import { fetchDebtSettlementAccounts } from "./debt_settlement";
import { selectDismissal, selectFeatureFlag } from "./page";
import { selectIsPremiumOrUltimate } from "./shopping";

const initialState = {
  tokens: null as Negotiation.Token[],
  byToken: {} as Negotiation.ByToken,
  eligibility: null as Negotiations.Eligibility,
  creditors: null as Negotiation.Creditor[],
  ceaseAndDesistLetters: null as Negotiation.CeaseAndDesistLetter[],
};

export type NegotiationsState = typeof initialState;

const negotiationsSlice = createSlice({
  name: "negotiations",
  initialState,
  reducers: {
    setNegotiations(state, { payload }: PayloadAction<Negotiation[]>) {
      const negotiations = payload;

      state.tokens = negotiations.map((negotiation) =>
        Negotiation.token(negotiation)
      );
      state.byToken = Negotiation.ByToken.fromList(negotiations);
    },
    setEligibility(
      state,
      { payload }: PayloadAction<NegotiationsState["eligibility"]>
    ) {
      state.eligibility = payload;
    },
    setCreditors(
      state,
      { payload }: PayloadAction<NegotiationsState["creditors"]>
    ) {
      state.creditors = payload;
    },
    setCeaseAndDesistLetters(
      state,
      { payload }: PayloadAction<Negotiation.CeaseAndDesistLetter[]>
    ) {
      state.ceaseAndDesistLetters = payload;
    },
  },
});

const { actions } = negotiationsSlice;
export const {} = actions;
export default negotiationsSlice.reducer;

export namespace Negotiations {
  export namespace Debt {
    export const isNewUser = (debtNegotiations: Negotiation.Debt[]) =>
      debtNegotiations.every(
        (debtNegotiation) =>
          debtNegotiation.negotiationStatus ===
          web.public_.NegotiationStatus.ELIGIBLE
      );
  }

  export namespace CeaseAndDesist {
    export const isNewUser = (
      ceaseAndDesistLetters: Negotiation.CeaseAndDesistLetter[]
    ) => ceaseAndDesistLetters?.length === 0;
  }

  export type Eligibility = web.public_.GetNegotiationsResponse.Eligibility;
}

export type Negotiation = web.public_.INegotiation;
export namespace Negotiation {
  export type Token = string & {};
  export const token = (negotiation: Negotiation) => negotiation.debt.token;

  export type Type = keyof Negotiation;
  export const types = ["debt"] as Negotiation.Type[];

  export type ByToken = Record<Negotiation.Token, Negotiation>;
  export namespace ByToken {
    export const fromList = (negotiations: Negotiation[]) =>
      Table.createIndex(negotiations, [Negotiation.token]);
  }

  export type Debt = web.public_.IDebt;
  export namespace Debt {
    export namespace Servicing {
      export enum Stage {
        ELIGIBLE,
        IN_PROGRESS,
        SUCCESS,
        FAILURE,
      }

      export enum Priority {
        NONE,
        INFO,
        ACTION_AVAILABLE,
        ACTION_REQUIRED,
      }

      export type Context = "home" | "account" | "accounts-card" | "overlay";

      export type State = {
        stage: Stage;
        priority: Priority;
        StatusText: React.FC<{ context: Context }>;
        NextStep?: React.FC<{ context: Context }>;
        Cta?: React.FC<{ context: Context }>;
      };
    }
    export type NumberOfPayments = number;
    export type OfferType = typeof Negotiation.Debt.offerTypes[number];
    export const offerTypes = ["one-time", "monthly"] as const;
    export const cadenceByOfferType: Record<OfferType, web.public_.Cadence> = {
      "one-time": web.public_.Cadence.ONE_TIME,
      monthly: web.public_.Cadence.MONTHLY,
    };

    const nameByCreditor: Record<web.public_.Debt.Creditor, string> = {
      [web.public_.Debt.Creditor.LVNV]: "LVNV Funding",
      [web.public_.Debt.Creditor.MIDLAND]: "Midland Credit Management",
      [web.public_.Debt.Creditor.CAPITAL_ONE]: "Capital One Bank",
      [web.public_.Debt.Creditor.JEFFERSON]: "Jefferson Capital",
      [web.public_.Debt.Creditor.FIRST_PREMIER]: "First Premier",
      [web.public_.Debt.Creditor.PORTFOLIO_RECOVERY_ASSOCIATES]:
        "Portfolio Recovery Associates",
      [web.public_.Debt.Creditor.CREDIT_COLLECTIONS]: "Credit Collections",
    };
    const discountByCreditor: Record<web.public_.Debt.Creditor, number> = {
      [web.public_.Debt.Creditor.LVNV]: 0.28,
      [web.public_.Debt.Creditor.MIDLAND]: 0.37,
      [web.public_.Debt.Creditor.CAPITAL_ONE]: 0.33,
      [web.public_.Debt.Creditor.JEFFERSON]: 0.17,
      [web.public_.Debt.Creditor.FIRST_PREMIER]: 0.3,
      [web.public_.Debt.Creditor.PORTFOLIO_RECOVERY_ASSOCIATES]: 0.3,
      [web.public_.Debt.Creditor.CREDIT_COLLECTIONS]: 0.3,
    };
    const discount = (debt: Negotiation.Debt) =>
      discountByCreditor[debt.creditor] ?? 0.3;

    export const estimateBalance = (debt: Negotiation.Debt) =>
      debt.balanceCents * (1 - discount(debt));

    export const minimumMonthlyPaymentCents = 1000;
    export const maxNumberOfPayments = 12;
    export const calculateMaxNumberOfPayments = (debt: Negotiation.Debt) =>
      Math.min(
        maxNumberOfPayments,
        Math.ceil(
          Negotiation.Debt.estimateBalance(debt) / minimumMonthlyPaymentCents
        )
      );

    export const calculateMinimumMonthlyPayment = (debt: Negotiation.Debt) =>
      Math.max(
        minimumMonthlyPaymentCents,
        Math.ceil(Negotiation.Debt.estimateBalance(debt) / maxNumberOfPayments)
      );

    export const calculateMonthlyPayment = (debt: Negotiation.Debt) =>
      Math.max(
        Math.ceil(
          Negotiation.Debt.estimateBalance(debt) /
            debt.negotiationPreference.numberOfPayments
        ),
        minimumMonthlyPaymentCents
      );

    export const humanizedEstimatedDiscount = (debt: Negotiation.Debt) =>
      `${Math.round(discount(debt) * 100)}%`;
    export const creditorName = ({ creditor }: Negotiation.Debt) =>
      nameByCreditor[creditor];
    export const creditors = () => Object.values(nameByCreditor);

    export const negotiationStatusName = ({
      negotiationStatus,
    }: Negotiation.Debt) => web.public_.NegotiationStatus[negotiationStatus];

    export namespace daysTo {
      export const negotiate = 3;
      export const acceptOffer = 5;
    }
    export const firstPaymentDateRange = (
      requestDate = startOfDay(new Date())
    ) => {
      return {
        start: addBusinessDays(
          requestDate,
          Negotiation.Debt.daysTo.negotiate +
            Negotiation.Debt.daysTo.acceptOffer
        ),
        end: addBusinessDays(requestDate, 33),
      };
    };

    export const daysToFirstPayment = ({ offerDetails }: Negotiation.Debt) =>
      Math.ceil(
        (protoTime(offerDetails.firstPaymentDate) - serverNow()) / msIn.day
      );

    export const firstPaymentAmount = ({ offerDetails }: Negotiation.Debt) => {
      if (offerDetails.cadence === web.public_.Cadence.MONTHLY) {
        return offerDetails.paymentSchedule[0].amountCents;
      }

      return offerDetails.amountCents;
    };

    export const monthlyPaymentAmount = ({ offerDetails }: Negotiation.Debt) =>
      offerDetails.paymentSchedule[1].amountCents;

    export const isEligible = ({ negotiationStatus }: Negotiation.Debt) =>
      [
        web.public_.NegotiationStatus.ELIGIBLE,
        web.public_.NegotiationStatus.USER_CANCELLED,
        web.public_.NegotiationStatus.NO_OFFER,
        web.public_.NegotiationStatus.OFFER_EXPIRED,
      ].includes(negotiationStatus);

    export const savings = ({ balanceCents, offerDetails }: Negotiation.Debt) =>
      balanceCents - offerDetails.amountCents;

    export const type = ({ type }: Negotiation.Debt) =>
      sentenceCase(web.public_.Debt.DebtType[type], "_", "-");
  }

  export type Creditor = web.public_.ICreditor;

  export type CeaseAndDesistLetter = web.public_.ICeaseAndDesistLetter;
  export namespace CeaseAndDesistLetter {
    export type Token = string & {};

    export const DeliveryStatus =
      web.public_.CeaseAndDesistLetter.DeliveryStatus;

    const DeliveryStatusText = {
      [DeliveryStatus.UNKNOWN]: "In progress",
      [DeliveryStatus.SUBMITTED]: " Cease and Desist letter request submitted",
      [DeliveryStatus.SHIPPED]: "  Cease and Desist letter shipped",
      [DeliveryStatus.NO_MORE_COMMUNICATIONS]:
        "  No more collector communications",
    };

    export const deliveryStatusText = (letter: CeaseAndDesistLetter) => {
      return DeliveryStatusText[letter.deliveryStatus];
    };

    export const creditorNameByDebtCreditor: Record<
      web.public_.Debt.Creditor,
      string
    > = {
      [web.public_.Debt.Creditor.LVNV]: "LVNV FUNDING LLC",
      [web.public_.Debt.Creditor.MIDLAND]: "MIDLAND CREDIT MANAGEMENT",
      [web.public_.Debt.Creditor.CAPITAL_ONE]: "CAPITAL ONE",
      [web.public_.Debt.Creditor.JEFFERSON]: "JEFFERSON CAPITAL",
      [web.public_.Debt.Creditor.FIRST_PREMIER]: "",
      [web.public_.Debt.Creditor.PORTFOLIO_RECOVERY_ASSOCIATES]: "",
      [web.public_.Debt.Creditor.CREDIT_COLLECTIONS]: "",
    };
  }
  export namespace CeaseAndDesistLetters {
    export const mostRecentLetterSent = (letters: CeaseAndDesistLetter[]) => {
      let mostRecentLetterSent: CeaseAndDesistLetter | null = null;

      letters?.forEach((letter) => {
        if (
          (letter.userLetter?.submittedAt?.seconds || 0) >
          (mostRecentLetterSent?.userLetter?.submittedAt?.seconds || 0)
        ) {
          mostRecentLetterSent = letter;
        }
      });

      return mostRecentLetterSent;
    };

    // Check if any letters have been sent in the same calendar month
    export const eligibleForNewLetter = (letters: CeaseAndDesistLetter[]) => {
      if (!letters?.length) return true;

      const today = new Date();

      for (const letter of letters ?? []) {
        if (letter.userLetter?.submittedAt) {
          const submittedAt = protoDate(letter.userLetter.submittedAt);
          if (
            submittedAt.getFullYear() === today.getFullYear() &&
            submittedAt.getMonth() === today.getMonth()
          )
            return false;
        }
      }

      return true;
    };
  }
}

const selectNegotiationsLoaded = () => (state: RootState) =>
  state.negotiations.tokens;

export const selectNegotiations = Object.assign(
  createLoadableSelector(
    () => (state: RootState) =>
      state.negotiations.tokens?.map(
        (token) => state.negotiations.byToken[token]
      ),
    {
      loadAction: () => fetchNegotiations(),
      selectLoaded: selectNegotiationsLoaded,
    }
  ),
  Object.fromEntries(
    Negotiation.types.map((type) => [
      type,
      createLoadableSelector(
        () => (state: RootState) =>
          state.negotiations.tokens?.flatMap(
            (token) => state.negotiations.byToken[token][type] || []
          ),
        {
          loadAction: () => fetchNegotiations(),
          selectLoaded: selectNegotiationsLoaded,
        }
      ),
    ])
  ) as {
    [Type in Negotiation.Type]: createLoadableSelector.Result<
      [],
      Negotiation[Type][]
    >;
  }
);

export const selectNegotiation = createLoadableSelector(
  (token: Negotiation.Token) => (state: RootState) =>
    state.negotiations.byToken[token],
  {
    loadAction: () => fetchNegotiations(),
    selectLoaded: selectNegotiationsLoaded,
  }
);

export const selectEligibility = createLoadableSelector(
  () => (state: RootState) => state.negotiations.eligibility,
  {
    loadAction: () => fetchNegotiations(),
  }
);

export const selectCreditors = createLoadableSelector(
  () => (state: RootState) => state.negotiations.creditors,
  {
    loadAction: () => fetchCreditors(),
  }
);

export const selectCeaseAndDesistLetters = createLoadableSelector(
  () => (state: RootState) => state.negotiations.ceaseAndDesistLetters,
  {
    loadAction: () => fetchCeaseAndDesistLetters(),
  }
);

export const selectCeaseAndDesistLetterByCreditor = createLoadableSelector(
  (creditorName: string) => (state: RootState) =>
    state.negotiations.ceaseAndDesistLetters?.find(
      (letter) => letter.creditor?.name === creditorName
    ),
  {
    dependsOn: [selectCeaseAndDesistLetters],
  }
);

export const selectCeaseAndDesistLetterByToken = createLoadableSelector(
  (token: Negotiation.CeaseAndDesistLetter.Token) => (state: RootState) =>
    state.negotiations.ceaseAndDesistLetters?.find(
      (letter) => letter.token === token
    ),
  {
    dependsOn: [selectCeaseAndDesistLetters],
  }
);

export const selectDebtNegotiationsEligibleToStart = () => (
  state: RootState
) => {
  const eligibility = selectEligibility()(state);

  return (
    eligibility === null ||
    eligibility === web.public_.GetNegotiationsResponse.Eligibility.ELIGIBLE
  );
};

export const daysToNextMonth = () => {
  const today = new Date();
  return differenceInDays(startOfMonth(addMonths(today, 1)), today);
};

export const selectDebtNegotiationServicingState = (() => {
  let ref = null;
  let cache = null;

  return () => (state: RootState) => {
    const debtNegotiations = selectNegotiations.debt()(state);
    const eligibleToStart = selectDebtNegotiationsEligibleToStart()(state);

    if (!debtNegotiations) return null;

    const nextRef = Tuple(state.negotiations.byToken, eligibleToStart);
    if (ref === nextRef) return cache as never;
    ref = nextRef;

    const servicingStateByToken = Object.fromEntries(
      debtNegotiations
        .map(
          (debtNegotiation) =>
            [
              debtNegotiation.token as Negotiation.Token,
              {
                ...((): Omit<
                  Negotiation.Debt.Servicing.State,
                  "debtNegotiation"
                > => {
                  const {
                    creditor,
                    token,
                    negotiationStatus,
                    balanceCents,
                    offerDetails,
                    specialInstructions,
                  } = debtNegotiation;

                  switch (negotiationStatus) {
                    case web.public_.NegotiationStatus.ELIGIBLE:
                      return {
                        stage: Negotiation.Debt.Servicing.Stage.ELIGIBLE,
                        priority:
                          Negotiation.Debt.Servicing.Priority.ACTION_AVAILABLE,
                        StatusText({ context }) {
                          return (
                            <div
                              className={cls(
                                context === "home" && "color:error"
                              )}
                            >
                              {Negotiation.Debt.type(debtNegotiation)} account
                            </div>
                          );
                        },
                        NextStep({ context }) {
                          const days = daysToNextMonth();

                          return (
                            <>
                              {renderFirstTruthyNode(
                                () =>
                                  context === "home" &&
                                  eligibleToStart && (
                                    <div>
                                      New debt negotiation is available. Select
                                      an account and request an offer.
                                    </div>
                                  ),
                                () =>
                                  !eligibleToStart && (
                                    <div>
                                      You are currently in the process of debt
                                      negotiation for another account. Please
                                      return in {days} days to begin
                                      negotiations for this account.
                                    </div>
                                  )
                              )}
                            </>
                          );
                        },
                        Cta({ context }) {
                          return (
                            <>
                              {renderFirstTruthyNode(
                                () =>
                                  context === "home" &&
                                  eligibleToStart && (
                                    <KLink href="/dashboard/negotiations/debt/start">
                                      <KButton.RequireContext>
                                        Negotiate now
                                      </KButton.RequireContext>
                                    </KLink>
                                  ),
                                () =>
                                  eligibleToStart && (
                                    <KLink
                                      href={`/dashboard/negotiations/debt/start/${token}/offer-type`}
                                    >
                                      <KButton.RequireContext>
                                        Request offer
                                      </KButton.RequireContext>
                                    </KLink>
                                  )
                              )}
                            </>
                          );
                        },
                      };
                    case web.public_.NegotiationStatus.NEGOTIATION_REQUESTED:
                      return {
                        stage: Negotiation.Debt.Servicing.Stage.IN_PROGRESS,
                        priority: Negotiation.Debt.Servicing.Priority.INFO,
                        StatusText() {
                          return (
                            <div className="color:success">
                               Negotiation in progress
                            </div>
                          );
                        },
                        NextStep({ context }) {
                          const summary = (
                            <>
                              You have requested an offer for your{" "}
                              {Negotiation.Debt.creditorName(debtNegotiation)}{" "}
                              {Negotiation.Debt.type(debtNegotiation)} account (
                              {format.money(balanceCents)}) on{" "}
                              <b>
                                {format.date(
                                  debtNegotiation.negotiationPreference
                                    .requestedAt,
                                  "Mmm d"
                                )}
                              </b>
                              .
                            </>
                          );
                          const days = daysToNextMonth();

                          return context === "home" ? (
                            <div>
                              {summary} Please return in {days} days to
                              negotiate another account.
                            </div>
                          ) : (
                            <>
                              <div className="mb-2">{summary}</div>
                              <div>
                                We’ll notify you when we receive a response.{" "}
                                Please also keep an eye on your email for an
                                offer from{" "}
                                {Negotiation.Debt.creditorName(debtNegotiation)}
                                .
                              </div>
                            </>
                          );
                        },
                      };
                    case web.public_.NegotiationStatus.OFFER_RECEIVED:
                    case web.public_.NegotiationStatus.OFFER_AVAILABLE:
                      return {
                        stage: Negotiation.Debt.Servicing.Stage.IN_PROGRESS,
                        priority:
                          Negotiation.Debt.Servicing.Priority.ACTION_REQUIRED,
                        StatusText({ context }) {
                          return (
                            <>
                              <div className="color:success">
                                 Offer arrived
                              </div>
                              {["accounts-card", "home"].includes(context) && (
                                <div className="mt-0.5">
                                   First payment due on{" "}
                                  {format.date(
                                    offerDetails.firstPaymentDate,
                                    "Mmm d"
                                  )}
                                </div>
                              )}
                            </>
                          );
                        },
                        NextStep({ context }) {
                          const summary = (
                            <>
                              We’ve secured a settlement offer from{" "}
                              {Negotiation.Debt.creditorName(debtNegotiation)}.
                              Review your offer, and make payment(s). Your first
                              payment is due by{" "}
                              <b>
                                {format.date(
                                  offerDetails.firstPaymentDate,
                                  "Mmm d"
                                )}
                              </b>
                              .
                            </>
                          );
                          const email = useSelector(
                            (state) => state.user.proto.info.email
                          );

                          return context === "home" ? (
                            <div>Great news! {summary}</div>
                          ) : (
                            <>
                              <div className="mb-2">{summary}</div>
                              <div className="text:large+ mb-1">Next step:</div>
                              <div className="text:regular++">
                                {negotiationStatus ===
                                web.public_.NegotiationStatus.OFFER_RECEIVED
                                  ? `Please check the email associated with your Kikoff account (${email}) for details. It takes up to 72 business hours for the email to be sent.`
                                  : "Please refer to the special instructions to review your offer."}
                              </div>
                              {specialInstructions && (
                                <Alert
                                  className="mt-3"
                                  type="info"
                                  dugout
                                  title="Special instructions"
                                  description={specialInstructions}
                                />
                              )}
                            </>
                          );
                        },
                        Cta({ context }) {
                          const overlays = useOverlaysController();

                          if (context === "home") {
                            return (
                              <KLink
                                href={`/dashboard/negotiations/debt/${token}`}
                              >
                                <KButton.RequireContext>
                                  View details
                                </KButton.RequireContext>
                              </KLink>
                            );
                          }

                          return (
                            context === "account" && (
                              <>
                                <KButton.RequireContext
                                  onClick={() => {
                                    overlays.push(
                                      negotiationStatus ===
                                        web.public_.NegotiationStatus
                                          .OFFER_RECEIVED
                                        ? "src/pages/dashboard/negotiations/debt/_views/open_email"
                                        : "src/pages/dashboard/negotiations/debt/_views/follow_special_instructions"
                                    );
                                  }}
                                >
                                  Review my offer
                                  {negotiationStatus ===
                                    web.public_.NegotiationStatus
                                      .OFFER_RECEIVED && " via email"}
                                </KButton.RequireContext>
                                <KButton
                                  className="mt-3"
                                  variant="black-outline"
                                  size="full-width"
                                  onClick={() => {
                                    overlays.push(
                                      "src/pages/dashboard/negotiations/debt/_views/mark_as_paid",
                                      { token }
                                    );
                                  }}
                                >
                                  {debtNegotiation.offerDetails.cadence ===
                                  web.public_.Cadence.ONE_TIME
                                    ? "Mark as paid"
                                    : "I started paying monthly"}
                                </KButton>
                              </>
                            )
                          );
                        },
                      };
                    case web.public_.NegotiationStatus.PAYMENT_STARTED:
                      return {
                        stage: Negotiation.Debt.Servicing.Stage.SUCCESS,
                        priority: Negotiation.Debt.Servicing.Priority.INFO,
                        StatusText() {
                          return (
                            <div className="color:success">Payment started</div>
                          );
                        },
                        NextStep() {
                          return (
                            <>
                              <div className="text:regular++">Great job!</div>
                              <div>
                                Please note: Once your settlement is paid off
                                and on-time, this debt will be removed from your
                                credit report in 30-60 days.
                              </div>
                            </>
                          );
                        },
                      };
                    case web.public_.NegotiationStatus.PAID_OFF:
                      return {
                        stage: Negotiation.Debt.Servicing.Stage.SUCCESS,
                        priority: Negotiation.Debt.Servicing.Priority.INFO,
                        StatusText() {
                          return <div className="color:success">Paid off</div>;
                        },
                        NextStep() {
                          return (
                            <>
                              <div className="text:regular++">Great job!</div>
                              <div>
                                Please note: Once your settlement is paid off,
                                this debt will be removed from your credit
                                report in 30-60 days.
                              </div>
                            </>
                          );
                        },
                      };
                    case web.public_.NegotiationStatus.USER_CANCELLED:
                      return {
                        stage: Negotiation.Debt.Servicing.Stage.FAILURE,
                        priority:
                          Negotiation.Debt.Servicing.Priority.ACTION_AVAILABLE,
                        StatusText() {
                          return (
                            <div className="color:error">
                               Negotiation cancelled
                            </div>
                          );
                        },
                        NextStep({ context }) {
                          return (
                            <div>
                              You’ve cancelled your negotiation request for{" "}
                              {context === "home"
                                ? `your ${Negotiation.Debt.creditorName(
                                    debtNegotiation
                                  )}`
                                : "this"}{" "}
                              debt account.
                            </div>
                          );
                        },
                        Cta({ context }) {
                          return (
                            ["home", "account"].includes(context) &&
                            eligibleToStart && (
                              <KLink
                                href={`/dashboard/negotiations/debt/start/${token}/offer-type`}
                              >
                                <KButton.RequireContext>
                                  Request a new offer for this account
                                </KButton.RequireContext>
                              </KLink>
                            )
                          );
                        },
                      };
                    case web.public_.NegotiationStatus.NO_OFFER:
                      return {
                        stage: Negotiation.Debt.Servicing.Stage.FAILURE,
                        priority:
                          Negotiation.Debt.Servicing.Priority.ACTION_AVAILABLE,
                        StatusText() {
                          return (
                            <div className="color:error">
                               No offer available
                            </div>
                          );
                        },
                        NextStep({ context }) {
                          const summary = (
                            <>
                              We requested a money-saving offer for your{" "}
                              {Negotiation.Debt.creditorName(debtNegotiation)}{" "}
                              debt, but unfortunately{" "}
                              <span
                                className={cls(
                                  context === "home" && "color:error"
                                )}
                              >
                                no offers are available
                              </span>{" "}
                              at this time for this particular account. You can
                              submit a re-negotiation next month.
                            </>
                          );

                          return (
                            <>
                              <div>{summary}</div>
                              {context === "overlay" && (
                                <Card className="mt-1" outline raised>
                                  <ListTile
                                    title={debtNegotiation.accountName}
                                    subtitle={`${Negotiation.Debt.type(
                                      debtNegotiation
                                    )} account`}
                                    trailing={format.money(
                                      debtNegotiation.balanceCents
                                    )}
                                    propsFor={{
                                      title: { className: "text:large+" },
                                      subtitle: {
                                        className: "color:error",
                                      },
                                      trailing: {
                                        className: "text:large+",
                                      },
                                    }}
                                    noPadding
                                  />
                                </Card>
                              )}
                              {["account", "overlay"].includes(context) &&
                                specialInstructions && (
                                  <Alert
                                    className={
                                      context === "overlay" ? "mt-1" : "mt-3"
                                    }
                                    type="info"
                                    dugout
                                    title="Special instructions"
                                    description={specialInstructions}
                                  />
                                )}
                            </>
                          );
                        },
                        Cta({ context }) {
                          if (context === "home") {
                            return (
                              <KLink
                                href={`/dashboard/negotiations/debt/${token}`}
                              >
                                <KButton.RequireContext>
                                  View details
                                </KButton.RequireContext>
                              </KLink>
                            );
                          }

                          return (
                            ["account", "overlay"].includes(context) &&
                            eligibleToStart && (
                              <KLink
                                href={`/dashboard/negotiations/debt/start/${token}/offer-type`}
                              >
                                <KButton.RequireContext>
                                  Request a new offer for this account
                                </KButton.RequireContext>
                              </KLink>
                            )
                          );
                        },
                      };
                    case web.public_.NegotiationStatus.OFFER_EXPIRED:
                      return {
                        stage: Negotiation.Debt.Servicing.Stage.FAILURE,
                        priority: Negotiation.Debt.Servicing.Priority.INFO,
                        StatusText() {
                          const [ceaseAndDesistLetter] = useSelector(
                            selectCeaseAndDesistLetterByCreditor.load(
                              Negotiation.CeaseAndDesistLetter
                                .creditorNameByDebtCreditor[creditor]
                            )
                          );

                          return (
                            <>
                              <div className="color:error">
                                 Payment due on{" "}
                                {format.date(
                                  offerDetails.firstPaymentDate,
                                  "Mmm d"
                                )}{" "}
                                has passed
                              </div>
                              {ceaseAndDesistLetter && (
                                <div className="color:primary">
                                  {Negotiation.CeaseAndDesistLetter.deliveryStatusText(
                                    ceaseAndDesistLetter
                                  )}
                                </div>
                              )}
                            </>
                          );
                        },
                        NextStep({ context }) {
                          return (
                            <>
                              <div>
                                Your payment due on{" "}
                                {format.date(
                                  offerDetails.firstPaymentDate,
                                  "Mmm d"
                                )}{" "}
                                has passed.
                              </div>
                              {["account", "overlay"].includes(context) &&
                                specialInstructions && (
                                  <Alert
                                    className={
                                      context === "overlay" ? "mt-1" : "mt-3"
                                    }
                                    type="info"
                                    dugout
                                    title="Special instructions"
                                    description={specialInstructions}
                                  />
                                )}
                            </>
                          );
                        },
                        Cta({ context }) {
                          const showCeaseAndDesist = useSelector(
                            selectFeatureFlag(
                              "send_cease_and_desist_letter_experiment"
                            )
                          );
                          const [ceaseAndDesistLetters] = useSelector(
                            selectCeaseAndDesistLetters.load()
                          );
                          const [ceaseAndDesistCreditors] = useSelector(
                            selectCreditors.load()
                          );

                          const ceaseAndDesistCreditorName =
                            Negotiation.CeaseAndDesistLetter
                              .creditorNameByDebtCreditor[creditor];

                          // Only show entrypoint is flag is on, creditor is in the list and user has not sent a letter this month
                          if (
                            showCeaseAndDesist &&
                            ceaseAndDesistCreditors?.find(
                              (creditor) =>
                                creditor.name === ceaseAndDesistCreditorName
                            ) &&
                            Negotiation.CeaseAndDesistLetters.eligibleForNewLetter(
                              ceaseAndDesistLetters
                            )
                          ) {
                            const startHref = `/dashboard/cease-and-desist/start/${ceaseAndDesistCreditorName}/get-started`;

                            if (context === "account") {
                              return (
                                <Alert type="info" dugout>
                                  <div className="text:regular+">
                                    Send {ceaseAndDesistCreditorName} a Cease
                                    and Desist Letter now
                                  </div>
                                  <div className="text:small my-1">
                                    Tired of dealing with{" "}
                                    {ceaseAndDesistCreditorName}? Block all
                                    interaction from them with a cease and
                                    desist letter
                                  </div>
                                  <KButton
                                    variant="black"
                                    size="small"
                                    onClick={() => {
                                      track.tap(
                                        "Cease And Desist - Debt Negotiation Screen",
                                        { token }
                                      );
                                      Router.push(startHref);
                                    }}
                                  >
                                    Send Cease and Desist Letter
                                  </KButton>
                                </Alert>
                              );
                            }

                            return (
                              <KLink href={startHref}>
                                <KButton.RequireContext
                                  onClick={() => {
                                    track.tap(
                                      "Cease And Desist - Debt Negotiation Card - Account",
                                      { token }
                                    );
                                  }}
                                >
                                  Send Cease and Desist Letter
                                </KButton.RequireContext>
                              </KLink>
                            );
                          }
                          return (
                            context === "home" && (
                              <KLink
                                href={`/dashboard/negotiations/debt/${token}`}
                              >
                                <KButton.RequireContext>
                                  View details
                                </KButton.RequireContext>
                              </KLink>
                            )
                          );
                        },
                      };
                    case web.public_.NegotiationStatus.UNABLE_TO_COMPLETE:
                      return {
                        stage: Negotiation.Debt.Servicing.Stage.FAILURE,
                        priority:
                          Negotiation.Debt.Servicing.Priority.ACTION_AVAILABLE,
                        StatusText() {
                          return (
                            <div className="color:error">
                               Negotiation could not be completed
                            </div>
                          );
                        },
                        NextStep({ context }) {
                          return context === "home" ? (
                            <div>
                              We requested a money-saving offer for your{" "}
                              {Negotiation.Debt.creditorName(debtNegotiation)}{" "}
                              debt, but unfortunately{" "}
                              <span className="color:error">
                                your negotiation could not be completed
                              </span>{" "}
                              at this time for this particular account.{" "}
                            </div>
                          ) : (
                            <>
                              <div className="mb-2">
                                The negotiation for your{" "}
                                {Negotiation.Debt.creditorName(debtNegotiation)}{" "}
                                account could not be completed due to one or
                                more of the following reasons:
                              </div>
                              <NegotiationNotCompletedReasons />
                              {context === "overlay" && (
                                <Card className="mt-2" outline raised>
                                  <ListTile
                                    title={debtNegotiation.accountName}
                                    subtitle={`${Negotiation.Debt.type(
                                      debtNegotiation
                                    )} account`}
                                    trailing={format.money(
                                      debtNegotiation.balanceCents
                                    )}
                                    propsFor={{
                                      title: { className: "text:large+" },
                                      subtitle: { className: "color:error" },
                                      trailing: { className: "text:large+" },
                                    }}
                                    noPadding
                                  />
                                </Card>
                              )}
                              {specialInstructions && (
                                <Alert
                                  className="mt-2"
                                  type="info"
                                  dugout
                                  title="Special instructions"
                                  description={specialInstructions}
                                />
                              )}
                            </>
                          );
                        },
                        Cta({ context }) {
                          const hasEligibleNegotiation = debtNegotiations.some(
                            Negotiation.Debt.isEligible
                          );

                          if (context === "home") {
                            return (
                              <KLink
                                href={`/dashboard/negotiations/debt/${token}`}
                              >
                                <KButton.RequireContext>
                                  View details
                                </KButton.RequireContext>
                              </KLink>
                            );
                          }

                          return (
                            ["account", "overlay"].includes(context) &&
                            hasEligibleNegotiation &&
                            eligibleToStart && (
                              <KLink href="/dashboard/negotiations/debt/start">
                                <KButton.RequireContext>
                                  Request an offer for another account
                                </KButton.RequireContext>
                              </KLink>
                            )
                          );
                        },
                      };
                    default:
                      return {
                        stage: Negotiation.Debt.Servicing.Stage.ELIGIBLE,
                        priority: Negotiation.Debt.Servicing.Priority.NONE,
                        StatusText() {
                          return <>Collection account</>;
                        },
                      };
                  }
                })(),
                debtNegotiation,
              } as Negotiation.Debt.Servicing.State,
            ] as const
        )
        .map(([token, state]) => {
          const { NextStep, Cta } = state;

          const wrapWithCommon = <C extends React.FC<any>>(Component: C) =>
            Component &&
            (((props) => (
              <KLink.PropsProvider
                variant="container"
                replace={false}
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                <Component {...props} />
              </KLink.PropsProvider>
            )) as C);

          return [
            token,
            Object.assign(state, {
              NextStep: wrapWithCommon(NextStep),
              Cta: wrapWithCommon(Cta),
            }),
          ];
        })
    );

    return (cache = {
      byToken: servicingStateByToken,
      byStage: orderBy(
        Object.entries(servicingStateByToken).map(
          ([token, servicingState]) => ({
            ...servicingState,
            debtNegotiation: selectNegotiation(token)(state).debt,
          })
        ),
        ({ priority }) => priority,
        "desc"
      ).groupBy(
        ({ stage }) =>
          Negotiation.Debt.Servicing.Stage[
            stage
          ] as keyof typeof Negotiation.Debt.Servicing.Stage
      ),
    });
  };
})();

export const fetchNegotiations = Object.assign(
  () =>
    thunk((dispatch, getState) =>
      webRPC.Negotiations.getNegotiations({}).then(
        handleProtoStatus({
          SUCCESS(data) {
            const negotiations = data.negotiations;
            const eligibility = data.eligibility;

            dispatch(actions.setNegotiations(negotiations));
            dispatch(actions.setEligibility(eligibility));

            return negotiations;
          },
          _DEFAULT: handleFailedStatus("Failed to load negotiations."),
        })
      )
    ),
  {
    ifNotPresent: () =>
      thunk((dispatch, getState) => {
        const { tokens, byToken } = getState().negotiations;

        return Promise.resolve(
          tokens?.map((token) => byToken[token]) ||
            dispatch(fetchNegotiations())
        );
      }),
  }
);

export const startCeaseAndDesistLetter = (creditorName: string) =>
  thunk((dispatch) =>
    webRPC.Negotiations.startCeaseAndDesistLetter({
      creditor: creditorName,
    }).then(
      handleProtoStatus({
        SUCCESS(data) {
          track("Cease And Desist - Letter Started");
          dispatch(
            actions.setCeaseAndDesistLetters([data.ceaseAndDesistLetter])
          );
        },
        _DEFAULT: handleFailedStatus("Failed to enroll user."),
      })
    )
  );

export const submitCeaseAndDesistLetter = (letterToken: string) =>
  thunk((dispatch) =>
    webRPC.Negotiations.submitCeaseAndDesistLetter({
      ceaseAndDesistLetterToken: letterToken,
    }).then(
      handleProtoStatus({
        SUCCESS() {
          track("Cease And Desist - Letter Submitted");
          dispatch(fetchCeaseAndDesistLetters());
          dispatch(fetchCreditors());
        },
        _DEFAULT: handleFailedStatus(
          "Failed to submit cease and desist letter."
        ),
      })
    )
  );

export const fetchCreditors = () =>
  thunk((dispatch) =>
    webRPC.Negotiations.listCreditors({}).then(
      handleProtoStatus({
        SUCCESS(data) {
          dispatch(actions.setCreditors(data.creditors));
        },
        _DEFAULT: handleFailedStatus("Failed to fetch creditors."),
      })
    )
  );

export const fetchCeaseAndDesistLetters = () =>
  thunk((dispatch) =>
    webRPC.Negotiations.listCeaseAndDesistLetters({}).then(
      handleProtoStatus({
        SUCCESS(data) {
          dispatch(
            actions.setCeaseAndDesistLetters(data.ceaseAndDesistLetters)
          );
        },
        _DEFAULT: handleFailedStatus(
          "Failed to fetch cease and desist letters."
        ),
      })
    )
  );

export const useCustomPaymentEnabled = () => {
  return useSelector(selectFeatureFlag("debt_custom_payment_schedule"));
};

export const useDebtNegotiationsEnabled = () => {
  const [eligibility] = useSelector(selectEligibility.load());

  const ineligible = [
    web.public_.GetNegotiationsResponse.Eligibility.NOT_ELIGIBLE_STATE_BLOCKED,
    web.public_.GetNegotiationsResponse.Eligibility.NOT_ELIGIBLE_FF,
    web.public_.GetNegotiationsResponse.Eligibility
      .NOT_ELIGIBLE_NO_OPEN_CREDIT_LINE,
  ];

  return !ineligible.includes(eligibility);
};

export const useDebtNegotiationsEligible = () => {
  const isPremiumOrUltimate = useSelector(selectIsPremiumOrUltimate.load());

  return isPremiumOrUltimate;
};

export const useDebtNegotiationsPopups = (): PopupGroup => {
  const debtNegotiationsEnabled = useDebtNegotiationsEnabled();
  const [popups, setPopups] = useState<Popup[]>([]);
  const [debtNegotiations, debtNegotiationsLoading] = useSelector(
    selectNegotiations.debt.load()
  );

  useEffect(() => {
    if (
      debtNegotiationsEnabled &&
      !debtNegotiationsLoading &&
      debtNegotiations
    ) {
      const newPopups: Popup[] = debtNegotiations
        ?.map((debtNegotiation) => {
          switch (debtNegotiation?.negotiationStatus) {
            case web.public_.NegotiationStatus.OFFER_RECEIVED:
            case web.public_.NegotiationStatus.OFFER_AVAILABLE:
            case web.public_.NegotiationStatus.OFFER_EXPIRED:
              return {
                dismissalTag: "DEBT_NEGOTIATION_REVIEW_OFFER",
                dismissalToken: debtNegotiation.token,
                popupType: PopupType.OVERLAY,
                name:
                  "src/pages/dashboard/negotiations/debt/_views/review_offer",
                params: { token: debtNegotiation.token },
                isEligible: true,
              } as Popup;

            case web.public_.NegotiationStatus.NO_OFFER:
              return {
                dismissalTag: "DEBT_NEGOTIATION_OFFER_NOT_AVAILABLE",
                dismissalToken: debtNegotiation.token,
                popupType: PopupType.OVERLAY,
                name:
                  "src/pages/dashboard/negotiations/debt/_views/offer_not_available",
                params: { token: debtNegotiation.token },
                isEligible: true,
              } as Popup;

            case web.public_.NegotiationStatus.UNABLE_TO_COMPLETE:
              return {
                dismissalTag: "DEBT_NEGOTIATION_NOT_COMPLETED",
                dismissalToken: debtNegotiation.token,
                popupType: PopupType.OVERLAY,
                name:
                  "src/pages/dashboard/negotiations/debt/_views/negotiation_not_completed",
                params: { token: debtNegotiation.token },
                isEligible: true,
              } as Popup;

            default:
              return null;
          }
        })
        .filter(Boolean);

      setPopups(newPopups);
    }
  }, [debtNegotiationsEnabled, debtNegotiationsLoading]);

  return {
    popups,
    isLoading: debtNegotiationsLoading,
  };
};

// taken from dispute takeover dismissal token
export function debtNegotiationsTakeoverDismissalToken(): string {
  // Calculate this month window of dispute takeover
  const today = new Date();
  // For month, frontend is zero-based index while mobile is one-based index
  // Add one here to stay consistent
  const currentMonth = today.getMonth() + 1;
  const currentYear = today.getFullYear();
  return `${currentYear}-${currentMonth}`;
}
export const useDebtNegotiationsTakeoverEnabled = (): {
  isEligible: boolean;
  isLoading: boolean;
} => {
  const dismissalToken = debtNegotiationsTakeoverDismissalToken();

  const debtNegotiationsDismissed = useSelector(
    selectDismissal("DEBT_NEGOTIATION_TAKEOVER", dismissalToken)
  );

  const debtNegotiationsTakeoverExperimentEnabled =
    useBackendExperiment("debtNegotiationsTakeover") === "takeover";

  const eligibleToStart = useSelector(selectDebtNegotiationsEligibleToStart());
  const [debtNegotiations, loading] = useSelector(
    selectNegotiations.debt.load()
  );
  const eligibleDebtNegotiations = debtNegotiations?.filter(
    Negotiation.Debt.isEligible
  );

  return {
    isEligible:
      debtNegotiationsTakeoverExperimentEnabled &&
      eligibleToStart &&
      eligibleDebtNegotiations?.length > 0 &&
      !debtNegotiationsDismissed,
    isLoading: loading,
  };
};

export const useNegotiationsPaintedDoorEnabled = () => {
  const enabled = useSelector(
    selectFeatureFlag("debt_negotiations_painted_door")
  );
  const dismissed = useSelector(
    selectDismissal("DEBT_NEGOTIATION_PAINTED_DOOR")
  );
  const stateCode = useSelector((state) => state.user.proto.info.state);

  return (
    enabled && !dismissed && debtNegotiationsEligibleStates.includes(stateCode)
  );
};

const creditors = [
  "CREDIT COLLECTIONS",
  "FIRST PREMIER",
  "PORTFOLIO RECOVERY ASSOCIATES",
  "MERRICK BANK",
  "VERIZON WIRELESS",
  "CAINE & WEINER COMPANY INC",
  "DISCOVER BANK",
  "JPMCB CARD SERVICES",
  "OPENSKY CAPITAL BANK NA",
  "LEAD BANK",
  "NATIONAL CREDIT ADJUSTERS",
];

export function useOldestDebtAccount() {
  const dispatch = useDispatch();
  const error = useErrorConsumer();

  const credit = useSelector(selectCredit());

  const oldestDebtAccount =
    credit &&
    minBy(
      credit.accounts.filter((account) => {
        return (
          account.balanceAmountDollars !== 0 &&
          creditors.some((creditor) =>
            account.name.toUpperCase().includes(creditor)
          ) &&
          (account.accountStatus ===
            web.public_.AccountV2.PaymentInfo.Value.CHARGE_OFF ||
            account.accountStatus ===
              web.public_.AccountV2.PaymentInfo.Value.COLLECTION)
        );
      }),
      (account) => protoTime(account.dateOpened)
    );

  useEffect(() => {
    dispatch(initCreditV2.ifNotPresent()).catch((e) => {
      error.throw(e.message);
    });

    dispatch(fetchDebtSettlementAccounts.ifNotPresent()).catch((e) => {
      error.throw(e.message);
    });
  }, []);

  return oldestDebtAccount;
}
