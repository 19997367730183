import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FluentResource } from "@fluent/bundle";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CardElement } from "@stripe/react-stripe-js";
import { Stripe, StripeElements } from "@stripe/stripe-js";

import Strike from "@kikoff/components/src/v1/text/Strike";
import useErrorConsumer from "@kikoff/hooks/src/useErrorConsumer";
import { google, web } from "@kikoff/proto/src/protos";
import { webRPC } from "@kikoff/proto/src/rpc";
import { msIn } from "@kikoff/utils/src/date";
import { isBetween, serverNow } from "@kikoff/utils/src/number";
import {
  handleFailedStatus,
  handleProtoStatus,
  protoTime,
} from "@kikoff/utils/src/proto";
import { format } from "@kikoff/utils/src/string";

import { FailedPaymentData } from "@overlay/payments/fix_payment";
import { useOverlaysController } from "@src/overlay";
import { AppThunk, RootState } from "@store";
import analytics, { track } from "@util/analytics";
import { newReactLocalization } from "@util/l10n";
import { nativeDispatch } from "@util/mobile";
import {
  accountAutoPayEnabled,
  autoPayState,
  autoPayStatusIs,
} from "@util/payments";

import { createLoadableSelector } from "../utils";

import { addPaymentMethod, fetchPaymentMethods } from "./funds";
import { updateOrders } from "./shopping";
import { openSubscriptionAndCreditLine } from "./subscription";
import { fetchUser } from "./user";

const RESOURCES = {
  en: new FluentResource(`payment-submit-adding-payment-method = Adding payment method...
payment-submit-paying-credit-line = Paying credit line...
payment-submit-enabling-autopay = Enabling autopay...
payment-submit-scheduling-payment = Scheduling payment...
`),
  es: new FluentResource(`payment-submit-adding-payment-method = Agregar método de pago...
payment-submit-paying-credit-line = Pagando la línea de crédito...
payment-submit-enabling-autopay = Habilitando autopay...
payment-submit-scheduling-payment = Programación del pago...
`),
};

const initialState = {
  account: null as web.public_.ICreditLineAccount,
  closed: [] as web.public_.ICreditLineAccount[],
  signedAgreements: null as web.public_.ISignedCreditLineAgreement[],
};

export type CreditLineState = typeof initialState;

const creditLineSlice = createSlice({
  name: "creditLine",
  initialState,
  reducers: {
    setCreditLineAccount(
      state,
      { payload }: PayloadAction<CreditLineState["account"]>
    ) {
      state.account = payload;
    },
    setClosedCreditLineAccounts(
      state,
      { payload }: PayloadAction<CreditLineState["closed"]>
    ) {
      state.closed = payload;
    },
    setSignedCreditLineAgreements(
      state,
      { payload }: PayloadAction<CreditLineState["signedAgreements"]>
    ) {
      state.signedAgreements = payload;
    },
  },
});

export const {
  setCreditLineAccount,
  setClosedCreditLineAccounts,
  setSignedCreditLineAgreements,
} = creditLineSlice.actions;
export default creditLineSlice.reducer;

export const selectCreditAccount = (token?: string) => (
  state: RootState
): CreditLineState["account"] =>
  [state.creditLine.account, ...(state.creditLine.closed || [])].find(
    (line) => line?.token === token
  ) || state.creditLine.account;

export const selectCreditAccountOverdue = () => (state: RootState) =>
  // paymentDueDate is 0 when balance is 0
  (protoTime(selectCreditAccount()(state)?.paymentDueDate) || Infinity) <
  serverNow();

export const selectCreditAccountInGracePeriod = () => (state: RootState) =>
  isBetween(
    (serverNow() -
      (protoTime(selectCreditAccount()(state)?.paymentDueDate) || Infinity)) /
      msIn.day,
    [0, 30]
  );

export const selectCreditAccountOverdueBy = (
  delinquentDays: 30 | 60 | 90 | 120 | 150 | 180
) => (state: RootState) =>
  serverNow() >
  (protoTime(selectCreditAccount()(state)?.paymentDueDate) || Infinity) +
    delinquentDays * msIn.day;

export const selectSignedCreditLineAgreements = createLoadableSelector(
  () => (state) => state.creditLine.signedAgreements,
  {
    loadAction: () => fetchSignedCreditLineAgreements(),
  }
);

export const fetchCreditLineAccount = (): AppThunk<
  Promise<web.public_.CreditLineAccount>
> => async (dispatch) =>
  webRPC.CreditLine.listCreditLineDetails({}).then(
    handleProtoStatus({
      SUCCESS({ creditLine }) {
        dispatch(setCreditLineAccount(creditLine));
        return creditLine;
      },
      _DEFAULT: handleFailedStatus("Failed to get credit line info"),
    })
  );

export const fetchSignedCreditLineAgreements = (): AppThunk<
  Promise<web.public_.SignedCreditLineAgreement[]>
> => async (dispatch) =>
  webRPC.CreditLine.listCreditLineAgreements({}).then(
    handleProtoStatus({
      SUCCESS({ signedCreditLineAgreements }) {
        dispatch(setSignedCreditLineAgreements(signedCreditLineAgreements));
        return signedCreditLineAgreements;
      },
      _DEFAULT: handleFailedStatus(
        "Failed to get signed credit line agreements"
      ),
    })
  );

export const closeCreditLine = ({
  creditLineToken,
  closureReason,
  closureNote,
}): AppThunk<Promise<void>> => async (dispatch) =>
  webRPC.CreditLine.closeCreditLine({
    closureReason,
    creditLineToken,
    closureNote,
  }).then(
    handleProtoStatus({
      SUCCESS() {
        dispatch(fetchUser.creditLine());
      },
      _DEFAULT: handleFailedStatus("Failed to close. Please contact support."),
    })
  );

export const pauseCreditLine = ({
  creditLineToken,
  months,
}): AppThunk<Promise<void>> => async (dispatch) =>
  webRPC.CreditLine.pauseCreditLine({
    creditLineToken,
    months,
  }).then(
    handleProtoStatus({
      SUCCESS() {
        dispatch(fetchUser.creditLine());
      },
      _DEFAULT: handleFailedStatus("Failed to pause. Please contact support."),
    })
  );

type PayCreditLineBalanceOptions = {
  amount?: number;
  paymentMethodToken: string;
  discountToken?: string;
};

export const payCreditLineBalance = ({
  amount,
  paymentMethodToken,
  discountToken,
}: PayCreditLineBalanceOptions): AppThunk<Promise<void>> => (
  dispatch,
  getState
) => {
  const creditLine = getState().creditLine.account;
  const user = getState().user.proto;

  return webRPC.CreditLine.makeCreditLinePayment({
    amountCents: amount || creditLine.outstandingMinimumPaymentCents,
    paymentMethodToken,
    creditLineToken: creditLine.token,
    discountToken,
  })
    .then(
      handleProtoStatus({
        SUCCESS() {
          // if this is the first payment
          if (creditLine.numberOfPayments === 0) {
            analytics.convert("Make Credit Line Payment", {
              creditLineToken: creditLine.token,
            });
            analytics.only("Amplitude").track("Meta purchase event - FE", {
              expectedLtv: user.expectedLtv,
            });
          }
          dispatch(fetchUser.features());
        },
        _DEFAULT: handleFailedStatus("Failed to make credit line payment"),
      })
    )
    .catch((err) => {
      dispatch(fetchUser.creditLine());
      track("CA - Payment - Payment Error", {
        "error message": err.message,
      });

      throw err;
    });
};

export function useCreditLineSubmitPayment() {
  const dispatch = useDispatch();
  const error = useErrorConsumer();
  const overlays = useOverlaysController();

  const preview = useSelector((state) => state.shopping.checkoutPreview);

  const creditLine = useSelector((state) => state.creditLine.account);

  const needsFix = !autoPayStatusIs(creditLine, "SUCCESS");

  const [pendingText, setPendingText] = useState("");

  return {
    pendingText,
    submitPayment({
      paymentMethodToken,
      paymentAction,
      showPostFixPayment = false,
    }: {
      paymentMethodToken: string;
      showPostFixPayment?: boolean;
      paymentAction(context: {
        paymentMethodToken: string;
      }): AppThunk<Promise<void>>;
    }) {
      track.tap("CA - Payment - Submit Payment", {
        "payment type": "minimum",
        "fix payment": needsFix,
      });

      const failedPaymentMethods: FailedPaymentData[] = [];

      return attempt(paymentMethodToken, true);
      function attempt(methodToken: string, initial?: true): Promise<void> {
        if (!initial) track("CA - Payment - Retry Payment");

        setPendingText("Paying credit line...");
        return dispatch(
          paymentAction({
            paymentMethodToken: methodToken,
          })
        )
          .then(async () => {
            nativeDispatch("requestReview");

            // Set autopay to use new payment method if it was just fixed
            if (needsFix)
              webRPC.CreditLine.updateCreditLineSettings({
                creditLineToken: creditLine.token,
                autoPay: {
                  state: creditLine.autoPay.settings.state,
                  paymentMethodToken: methodToken,
                },
              });
            if (showPostFixPayment && failedPaymentMethods.length > 0)
              overlays.push("payments/post_fix_payment", {
                autopayEnabled: accountAutoPayEnabled(creditLine),
                failedPaymentMethods,
              });
            setPendingText("");
          })
          .catch((e) => {
            failedPaymentMethods.push([methodToken, e?.message]);
            dispatch(fetchPaymentMethods());
            setPendingText("Fixing payment...");
            return overlays
              .push("payments/fix_payment", {
                failedPaymentMethods,
                paymentOptions: (creditLine || preview).paymentOptions,
              })
              .then((token) => {
                if (token) return attempt(token);
                error.throw(e);
                setPendingText("");
                throw e;
              });
          });
      }
    },
  };
}

interface SetupCreditLinePaymentOptions {
  stripe: Stripe;
  elements: StripeElements;
  onUpdate(text: string): void;
  onNewPaymentMethodToken?(paymentMethodToken: string): void;
  paymentMethodToken?: string;
  autopayEnabled: boolean;
  payCreditLine?: boolean;
  openCreditLine?: boolean;
  cashapp?: boolean;
}

export const setupCreditLineRepayment = ({
  stripe,
  elements,
  onUpdate,
  onNewPaymentMethodToken,
  paymentMethodToken,
  autopayEnabled,
  payCreditLine,
  openCreditLine,
  cashapp = false,
}: SetupCreditLinePaymentOptions): AppThunk<
  Promise<{ paymentMethodToken: string }>
> => async (dispatch, getState) => {
  const l10n = newReactLocalization(RESOURCES);
  const getProduct = () =>
    getState().shopping.checkoutPreview.order.orderItems[0].product;
  const shoppingBag = getState().shopping.bag;

  const methodToken =
    paymentMethodToken ||
    (onUpdate(l10n.getString("payment-submit-adding-payment-method")),
    await dispatch(
      addPaymentMethod({
        paymentMethod: cashapp
          ? null
          : {
              card: elements.getElement(CardElement),
            },
        stripe,
        onUpdate,
        noRefresh: true,
        cashapp,
      })
    )
      .then((token) => {
        onNewPaymentMethodToken?.(token);
        return token;
      })
      .catch((err) => {
        err.name = "PaymentMethodError";
        throw err;
      }));

  dispatch(fetchPaymentMethods());

  const autoPay = {
    paymentMethodToken: methodToken,
    state: autoPayState(autopayEnabled),
  };

  if (openCreditLine) {
    track("CA - Activation - Activate");

    await dispatch(
      openSubscriptionAndCreditLine(
        autopayEnabled,
        getProduct().plan,
        autoPay,
        getProduct().token,
        shoppingBag.firstPaymentDate
      )
    ).then(() => {
      track("CA - Activation - Credit Line Opened");
      if (autopayEnabled) {
        analytics.convert("Enable Autopay");
      }
    });
  }

  if (payCreditLine) {
    const creditLine = getState().creditLine.account;
    if (creditLine.outstandingMinimumPaymentCents === 0) return;
    onUpdate(l10n.getString("payment-submit-paying-credit-line"));
    await dispatch(fetchUser.features());
    await dispatch(payCreditLineBalance({ paymentMethodToken: methodToken }));
  }

  if (autopayEnabled) {
    onUpdate(l10n.getString("payment-submit-enabling-autopay"));
    const creditLine = getState().creditLine.account;
    await webRPC.CreditLine.updateCreditLineSettings({
      creditLineToken: creditLine?.token,
      autoPay,
    });
  } else {
    onUpdate(l10n.getString("payment-submit-scheduling-payment"));
  }

  await dispatch(updateOrders());
  const creditLine = (await dispatch(fetchUser.creditLine())).openCreditLine;
  if (
    creditLine.delayedPayment?.status ===
    web.public_.CreditLineAccount.DelayedPayment.Status.FAILED
  )
    throw new Error(`Payment failed with: ${creditLine.delayedPayment.reason}`);

  return { paymentMethodToken: methodToken };
};

export const getAmountText = (creditLine: web.public_.ICreditLineAccount) => {
  const [amount, discount] = creditLine.outstandingMinimumPaymentCents
    ? [
        creditLine.outstandingMinimumPaymentCents +
          creditLine.currentStatementCreditAppliedCents,
        creditLine.currentStatementCreditAppliedCents,
      ]
    : [creditLine.minimumPaymentDueCents, creditLine.nextStatementCreditCents];

  return discount ? (
    <span>
      {format.money(amount - discount)}{" "}
      {discount > 0 && (
        <Strike className="color:moderate-weak">{format.money(amount)}</Strike>
      )}
    </span>
  ) : (
    format.money(amount)
  );
};

export function getAccountAge(openedAt: google.protobuf.ITimestamp) {
  return Math.floor((Date.now() - protoTime(openedAt)) / msIn.day);
}

export const limitCapInCents = 10000_00;
export const flatStrategyIncreaseCents = 500_00;
export const streakStrategyInitialIncreaseCents = 50_00;
