import { useSelector } from "react-redux";
import Router from "next/router";
import Cookie from "js-cookie";
import { LocalizationProvider, Localized } from "@fluent/react";

import { isClient } from "@kikoff/utils/src/general";
import { combineClasses } from "@kikoff/utils/src/string";

import {
  availableLocales,
  defaultLocale,
  humanizedLocaleLookup,
} from "@constant/locales";
import { track } from "@util/analytics";
import {
  getLocaleCookie,
  l10nResources,
  updateUserAccountLocale,
} from "@util/l10n";

import styles from "./styles.module.scss";

const l10n = l10nResources({
  en: `l10n-toggle-label = Language`,
  es: `l10n-toggle-label = Idioma`,
});

function LocaleButton({ currentLocale, locale, variant }) {
  const userIsAuthenticated = useSelector((state) => state.user.authenticated);

  return (
    <button
      className={combineClasses(
        styles["locale-toggle-button"],
        currentLocale === locale && styles["current-locale"],
        variant && styles[variant]
      )}
      data-cy={`toggle-locale-${locale}`}
      onClick={() => {
        track.tap("Account - Toggle Locale Button", { locale });

        const url = new URL(window.location.href);
        if (availableLocales.includes(locale)) {
          Cookie.set("locale", locale);
        }

        if (availableLocales.includes(locale) && locale !== defaultLocale) {
          url.searchParams.set("locale", locale);
        } else {
          url.searchParams.delete("locale");
        }

        Router.push(url);

        if (userIsAuthenticated) {
          updateUserAccountLocale(locale);
        }
      }}
      type="submit"
    >
      {variant === "manage-account" ? humanizedLocaleLookup[locale] : locale}
    </button>
  );
}

interface L10nToggleParams {
  variant?: "manage-account" | "light" | "dark"; // default is simple one in footer
}

function L10nToggle({ variant }: L10nToggleParams) {
  if (!isClient) {
    return null;
  }

  const currentLocale = getLocaleCookie();

  return (
    <div className={styles["toggle-card"]}>
      <LocalizationProvider l10n={l10n}>
        {variant !== "manage-account" && (
          <span className={styles["l10n-toggle-title"]}>
            <Localized id="l10n-toggle-label" />:
          </span>
        )}
        <div className={styles["l10n-button-wrapper"]}>
          {availableLocales.map((locale) => {
            return (
              <LocaleButton
                currentLocale={currentLocale}
                locale={locale}
                key={locale}
                variant={variant}
              />
            );
          })}
        </div>
      </LocalizationProvider>
    </div>
  );
}

export default L10nToggle;
