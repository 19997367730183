import { type AmplitudeClient } from "amplitude-js";
import Cookie from "js-cookie";

import { isLocalStorageAvailable } from "@kikoff/client-utils/src/local_storage";

let _amplitudeInstance: AmplitudeClient | null = null;
const isClient = typeof window !== "undefined";

export async function getAmplitudeInstance() {
  if (!isClient) return null;
  if (_amplitudeInstance) return _amplitudeInstance;

  const amplitude = (await import("amplitude-js")).default;

  _amplitudeInstance = amplitude.getInstance();
  _amplitudeInstance.init(process.env.AMPLITUDE_API_KEY, null, {
    includeGclid: true,
    includeReferrer: true,
    includeUtm: true,
    includeFbclid: true,
    saveEvents: true,
  });

  updateAmplitudeCookies(_amplitudeInstance)

  return _amplitudeInstance;
}

function updateAmplitudeCookies(amplitudeInstance?: AmplitudeClient) {
  if (!amplitudeInstance) return;

  // TODO (Almir): Remove this after May 1, 2025 as most users will have correct cookies set by that time
  if (isLocalStorageAvailable() && localStorage.getItem("amplitude_cookies_migration_version") !== "1") {
    Cookie.remove("amplitude_session_id");
    Cookie.remove("amplitude_device_id");
    localStorage.setItem("amplitude_cookies_migration_version", "1");
  };

  const domain = window.location.hostname.split(".").slice(-2).join(".");

  const sessionId = amplitudeInstance.getSessionId()?.toString() ?? null;
  const cookieSessionId = Cookie.get("amplitude_session_id") ?? null;
  if (cookieSessionId !== sessionId) {
    Cookie.set("amplitude_session_id", sessionId, { domain, expires: 1 });
  }
  
  const deviceId = amplitudeInstance.options?.deviceId ?? null;
  const cookieDeviceId = Cookie.get("amplitude_device_id") ?? null;
  if (cookieDeviceId !== deviceId) {
    Cookie.set("amplitude_device_id", deviceId, { domain, expires: 365 });
  }
}
