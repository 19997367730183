import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { kikoff } from "@kikoff/proto/src/protos";
import { webRPC } from "@kikoff/proto/src/rpc";
import { handleFailedStatus, handleProtoStatus } from "@kikoff/utils/src/proto";

import { RootState } from "@store";
import { track } from "@util/analytics";

import { createLoadableSelector, thunk } from "../utils";

import { dismiss } from "./page";

const initialState = {
  recordsByType: {} as Record<ScanRecord.Type, ScanRecord[]>,
};

export type PrivacyProtectionState = typeof initialState;

const privacyProtectionSlice = createSlice({
  name: "privacyProtection",
  initialState,
  reducers: {
    updateRecordsByType(
      state,
      {
        payload,
      }: PayloadAction<Partial<PrivacyProtectionState["recordsByType"]>>
    ) {
      Object.assign(state.recordsByType, payload);
    },
  },
});

const { actions } = privacyProtectionSlice;
export const {} = actions;
export default privacyProtectionSlice.reducer;

export const selectScanRecords = createLoadableSelector(
  (type: ScanRecord.Type) => (state: RootState) =>
    state.privacyProtection.recordsByType[type],
  {
    loadAction: (type) => fetchScanRecords(type),
  }
);

export const fetchScanRecords = (type: ScanRecord.Type) =>
  thunk((dispatch) =>
    webRPC.HelloPrivacyService.getScanRecords({
      recordType:
        kikoff.api.hello_privacy.GetScanRecordsRequest.RecordType[type],
    }).then(
      handleProtoStatus({
        SUCCESS(data) {
          dispatch(actions.updateRecordsByType({ [type]: data.scanRecords }));

          return data.scanRecords;
        },
        _DEFAULT() {
          throw new Error("Something went wrong.");
        },
      })
    )
  );

export const createArrayUserAndEnrollInIdp = () =>
  thunk((dispatch) =>
    webRPC.Array.createUserAndEnrollInIdp({}).then(
      handleProtoStatus({
        SUCCESS() {
          track("Hello Privacy - ID Theft Insurance - User Enrolled In IDP");
          dispatch(dismiss("ID_THEFT_INSURANCE_UPSELL"));
        },
        _DEFAULT: handleFailedStatus("Failed to enroll user."),
      })
    )
  );

export type ScanRecord = kikoff.api.hello_privacy.IScanRecord;
export namespace ScanRecord {
  export type Type = keyof typeof kikoff.api.hello_privacy.GetScanRecordsRequest.RecordType;
}
