let _localStorageAvailable = null;

export function isLocalStorageAvailable() {
  if (_localStorageAvailable !== null) return _localStorageAvailable;

  try {
    localStorage.setItem("test_local_storage", "test");
    localStorage.removeItem("test_local_storage");
    _localStorageAvailable = true;
  } catch (e) {
    _localStorageAvailable = false;
  }

  return _localStorageAvailable;
}
