import { useSelector } from "react-redux";
import Router from "next/router";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { getScript } from "@kikoff/client-utils/src/dom";
import { web } from "@kikoff/proto/src/protos";
import { webRPC } from "@kikoff/proto/src/rpc";
import { handleFailedStatus, handleProtoStatus } from "@kikoff/utils/src/proto";

import { useBackendExperiment } from "@src/experiments/context";
import { RootState } from "@store";

import { createLoadableSelector, thunk } from "../utils";

import { selectDismissal, selectFeatureFlag } from "./page";
import { selectIsBasic } from "./shopping";
import { selectHasActiveSecuredCard } from "./user";

export namespace TaxFiling {
  export const filingYear = 2024;

  export type FeeType = keyof typeof web.public_.TaxFeeType;
  export const centsByFeeType: Record<TaxFiling.FeeType, number> = {
    FILING: 900,
    EXPERT: 8900,
  };
}

const initialState = {
  currentYearFiling: null as web.public_.IFiling,
  taxRefunds: null as web.public_.ITaxRefund[],
  paidFilingFee: null as boolean,
};

export type ColumnTaxState = typeof initialState;

const taxFilingSlice = createSlice({
  name: "columnTax",
  initialState,
  reducers: {
    setCurrentYearFiling(
      state,
      { payload }: PayloadAction<ColumnTaxState["currentYearFiling"]>
    ) {
      state.currentYearFiling = payload;
    },
    setTaxRefunds(
      state,
      { payload }: PayloadAction<ColumnTaxState["taxRefunds"]>
    ) {
      state.taxRefunds = payload;
    },
    setPaidFilingFee(
      state,
      { payload }: PayloadAction<ColumnTaxState["paidFilingFee"]>
    ) {
      state.paidFilingFee = payload;
    },
  },
});

const { actions } = taxFilingSlice;
export const {} = actions;
export default taxFilingSlice.reducer;

export const selectCurrentYearFiling = createLoadableSelector(
  () => (state: RootState) => state.taxFiling.currentYearFiling,
  {
    loadAction: () => fetchCurrentYearTaxFiling(),
  }
);

export const selectTaxRefunds = () => (state: RootState) =>
  state.taxFiling.taxRefunds;

export const selectTaxPaidFilingFee = createLoadableSelector(
  () => (state: RootState) => state.taxFiling.paidFilingFee,
  {
    loadAction: () => fetchCurrentYearTaxFiling(),
  }
);

export const fetchCurrentYearTaxFiling = () =>
  thunk((dispatch) =>
    webRPC.ColumnTax.getFiling({}).then(
      handleProtoStatus({
        SUCCESS(data) {
          dispatch(actions.setCurrentYearFiling(data.filing));
          dispatch(actions.setPaidFilingFee(data.paidFilingFee));
          return data;
        },
        _DEFAULT: handleFailedStatus("Failed get tax return link"),
      })
    )
  );

export const fetchTaxRefunds = Object.assign(
  () =>
    thunk((dispatch) =>
      webRPC.ColumnTax.getFinalizedTaxRefunds({}).then(
        handleProtoStatus({
          SUCCESS(data) {
            dispatch(actions.setTaxRefunds(data.taxRefunds));
            return data;
          },
          _DEFAULT: handleFailedStatus("Failed to get tax refunds"),
        })
      )
    ),
  {
    ifNotPresent: () =>
      thunk((dispatch, getState) => {
        const { taxRefunds } = getState().taxFiling;

        return Promise.resolve(taxRefunds || dispatch(fetchTaxRefunds()));
      }),
  }
);

export const makeTaxFilingPayment = (
  feeType: TaxFiling.FeeType,
  paymentMethodToken: string
) =>
  thunk((dispatch) =>
    webRPC.ColumnTax.makeTaxFilingPayment({
      feeType: web.public_.TaxFeeType[feeType],
      paymentMethodToken,
    }).then(
      handleProtoStatus({
        SUCCESS() {
          return dispatch(fetchCurrentYearTaxFiling());
        },
        _DEFAULT: handleFailedStatus("Failed to make tax filing payment."),
      })
    )
  );

declare const ColumnTax: {
  openModule(options: {
    userUrl: string;
    environment: string;
    onClose: () => void;
    style?: React.CSSProperties;
  }): void;
};

export const openColumnTaxModule = ({
  onClose,
  onCloseUrl,
}: { onClose?: () => void; onCloseUrl?: string } = {}) =>
  thunk((dispatch) =>
    webRPC.ColumnTax.submitFiling({}).then(
      handleProtoStatus({
        SUCCESS({ url }) {
          return getScript("https://app.columnapi.com/column-tax.js").then(
            () => {
              ColumnTax.openModule({
                userUrl: url,
                environment: process.env.REALLY_PRODUCTION
                  ? "production"
                  : "sandbox",
                onClose() {
                  dispatch(fetchCurrentYearTaxFiling());
                  onClose?.();
                  if (onCloseUrl) {
                    Router.replace(onCloseUrl);
                  }
                },
              });
            }
          );
        },
        _DEFAULT: handleFailedStatus("Failed to fetch tax return URL"),
      })
    )
  );

type TaxFilingVariant = "control" | "treatment";

export const useTaxFilingVariant = () => {
  const jan3Enabled = useSelector(selectFeatureFlag("tax_filing_waitlist_ui"));
  const jan3Variant = useBackendExperiment(
    "taxFilingWaitlist"
  ) as TaxFilingVariant;
  const jan6Enabled = useSelector(selectFeatureFlag("tax_filing2024_ui"));
  const jan6Variant = useBackendExperiment("taxFiling2024") as TaxFilingVariant;

  if (
    (jan3Enabled && jan3Variant === "treatment") ||
    (jan6Enabled && jan6Variant === "treatment")
  ) {
    return "treatment";
  }

  return "control";
};

export const useTaxFilingEligible = () => {
  const variant = useTaxFilingVariant();
  const [isBasic, basicLoading] = useSelector(selectIsBasic.load());
  const hasActiveSecuredCard = useSelector(selectHasActiveSecuredCard());
  const [hasPaidFiling, taxLoading] = useSelector(
    selectTaxPaidFilingFee.load()
  );
  const shouldSkipSc = useSelector(selectDismissal("TAX_FILING_OPEN_SC"));
  const hasExpertResponse = useSelector(selectDismissal("TAX_FILING_EXPERT"));

  const eligible = (() => {
    if (variant === "control") {
      return false;
    }

    if (isBasic) {
      return hasPaidFiling;
    }

    return (shouldSkipSc || hasActiveSecuredCard) && hasExpertResponse;
  })();

  return [eligible, basicLoading || taxLoading];
};
