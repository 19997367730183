import React, { ReactNode, useState } from "react";
import { FluentResource } from "@fluent/bundle";
import { LocalizationProvider, Localized } from "@fluent/react";

import Collapsible from "@kikoff/components/src/v1/containers/Collapsible";
import KLink from "@kikoff/components/src/v1/navigation/KLink";

import { ContainerButton } from "@component/buttons/buttons";
import PlusExpander from "@component/expanders/plus_expander";
import {
  blogUrl,
  getCreditAccountMentalModelName,
  getKikoffCreditAccountMentalModelName,
} from "@src/constants";
import { track } from "@util/analytics";
import { getLocaleCookie, newReactLocalization } from "@util/l10n";

import styles from "./styles.module.scss";

const RESOURCES = {
  en: new FluentResource(`contact-us = Contact us
more-links-title = More questions?
more-links-faq = CHECK OUT OUR FAQ
more-links-blog = CHECK OUT OUR BLOG
landing-faq-title = Have questions?
`),
  es: new FluentResource(`contact-us = Contacta con nosotros
more-links-title = ¿Más preguntas?
more-links-faq = CONSULTA NUESTRAS PREGUNTAS FRECUENTES
more-links-blog = CONSULTA NUESTRO BLOG
landing-faq-title = ¿Tiene preguntas?
`),
};

const l10n = newReactLocalization(RESOURCES);

const faqs = () => {
  return [
    {
      question: {
        en: "How does Kikoff work?",
        es: "¿Cómo funciona Kikoff?",
      },
      answer: {
        en: (
          <>
            <p>
              Kikoff is a credit-building service designed to help you build
              credit safely and responsibly. Our products are designed to target
              the{" "}
              <KLink href="https://www.equifax.com/personal/education/credit/score/articles/-/learn/what-affects-credit-score-infographic/">
                5 major credit score factors
              </KLink>
              .
            </p>
            <p>
              <ul>
                <li>
                  <b>{getCreditAccountMentalModelName()}</b> - this is our
                  primary product: a revolving line of credit designed to
                  address the 3 key factors of your credit score: payment
                  history, credit utilization, and age of accounts.
                </li>
                <ul>
                  <li>
                    Build monthly payment history: Use your{" "}
                    {getCreditAccountMentalModelName()} to finance a purchase
                    from Kikoff and make monthly payments without interest or
                    fees to build your payment history. We’ll report each of
                    your payments to Equifax, Experian, and TransUnion.
                  </li>
                  <li>
                    Reduce your credit utilization: Signing up for Kikoff gives
                    you access to a line of credit to make purchases from
                    Kikoff.
                  </li>
                  <li>
                    Raise your age of accounts: The older your accounts, the
                    better. The {getCreditAccountMentalModelName()} doesn’t
                    expire—so keeping it open will continue to increase your
                    average account age.
                  </li>
                </ul>
                <li>
                  <b>Secured Credit Card</b> - this is a secured credit card
                  product that safely builds your payment history through your
                  everyday transactions. The Secured Credit Card works like a
                  debit card but has the benefits of a credit card.
                </li>
              </ul>
            </p>
          </>
        ),
        es: (
          <>
            <p>
              Kikoff es un servicio de creación de crédito diseñado para
              ayudarlo a crear crédito de manera segura y responsable. Nuestros
              productos están diseñados para enfocarse en los{" "}
              <KLink href="https://www.equifax.com/personal/education/credit/score/what-affects-credit-score-infographic/">
                5 principales factores de calificación crediticia
              </KLink>
              .
            </p>
            <p>
              <ul>
                <li>
                  <b>{getCreditAccountMentalModelName()}</b> - este es nuestro
                  producto principal: una línea de crédito renovable diseñada
                  para abordar los 3 factores clave de su puntaje crediticio:
                  historial de pago, utilización del crédito y antigüedad de las
                  cuentas.
                </li>
                <ul>
                  <li>
                    Cree un historial de pago mensual: use su{" "}
                    {getCreditAccountMentalModelName()} para financiar una
                    compra en Kikoff y realizar pagos mensuales sin intereses ni
                    comisiones para construir tu historial de pagos.
                    Informaremos cada uno de sus pagos a Equifax, Experian, y
                    TransUnion.
                  </li>
                  <li>
                    Reduzca la utilización de su crédito: Registrarse en Kikoff
                    le da acceso a una línea de crédito para realizar compras en
                    Kikoff.
                  </li>
                  <li>
                    Aumente la antigüedad de sus cuentas: cuanto más antiguas
                    sean sus cuentas, mejor. El{" "}
                    {getCreditAccountMentalModelName()} no caduca, por lo que
                    mantenerlo abierto seguirá aumentando la edad promedio de su
                    cuenta.
                  </li>
                </ul>
                <li>
                  <b>Tarjeta de crédito asegurada</b> - este es un producto de
                  tarjeta de crédito seguro que crea de manera segura su
                  historial de pago a través de sus transacciones diarias. La
                  tarjeta de crédito asegurada funciona como una tarjeta de
                  débito pero tiene los beneficios de una tarjeta de crédito.
                </li>
              </ul>
            </p>
          </>
        ),
      },
    },
    {
      question: {
        en: "Is this a credit card?",
        es: "¿Es esta una tarjeta de crédito?",
      },
      answer: {
        en: (
          <>
            <p>Kikoff offers multiple products.</p>
            <p>
              <ul>
                <li>
                  The <b>{getCreditAccountMentalModelName()}</b> is a revolving
                  line of credit that gets reported to Equifax, Experian, and
                  TransUnion. You are able to use the{" "}
                  {getKikoffCreditAccountMentalModelName()} to finance the
                  purchase of goods and services from Kikoff. Unlike a credit
                  card, the {getKikoffCreditAccountMentalModelName()} can only
                  be used to make purchases from Kikoff (e.g. can’t buy gas or
                  groceries). There is no physical card with the{" "}
                  {getKikoffCreditAccountMentalModelName()}.
                </li>

                <li>
                  The <b>Secured Credit Card</b> is a secured credit card on the
                  Mastercard network that works like a checking account and
                  debit card. The funds that are deposited set the credit limit
                  of the card so you never have to worry about overspending or
                  due dates.
                </li>
              </ul>
            </p>
          </>
        ),
        es: (
          <>
            <p>Kikoff ofrece múltiples productos</p>
            <p>
              <ul>
                <li>
                  <b>{getCreditAccountMentalModelName()}</b> es una línea de
                  crédito renovable que se informa a Equifax, Experian, y
                  TransUnion. Puede utilizar{" "}
                  {getKikoffCreditAccountMentalModelName()} para financiar la
                  compra de bienes y servicios de Kikoff. A diferencia de una
                  tarjeta de crédito, {getKikoffCreditAccountMentalModelName()}{" "}
                  solo se puede usar para realizar compras en Kikoff (por
                  ejemplo, no se puede comprar gasolina ni comestibles). No hay
                  ninguna tarjeta física con{" "}
                  {getKikoffCreditAccountMentalModelName()}.
                </li>
                <li>
                  La <b>Tarjeta de crédito asegurada</b> es una tarjeta de
                  crédito asegurada en la red Mastercard que funciona como una
                  cuenta corriente y una tarjeta de débito. Los fondos que se
                  depositan establecen el límite de crédito de la tarjeta para
                  que nunca tenga que preocuparse por gastos excesivos o fechas
                  de vencimiento.
                </li>
              </ul>
            </p>
          </>
        ),
      },
    },
    {
      question: {
        en: "How much will Kikoff improve my credit score?",
        es: "¿Cuánto mejorará Kikoff mi puntaje de crédito?",
      },
      answer: {
        en: (
          <>
            <p>
              On average, customers with credit scores under 600 increase their
              credit scores by 58 points[1], with consistent on-time payments
              during the account lifetime. Many Kikoff customers have been able
              to qualify for better car loans, mortgages, credit cards, personal
              loans, and more.
            </p>
            <p>
              Keep in mind, credit building is a long-term habit – like eating
              healthy or working out – so the most important thing is to make
              good financial habits: make your payments on-time, keep your
              utilization low, and{" "}
              <KLink href={blogUrl("/how-do-i-get-a-700-credit-score")}>
                practice good financial habits
              </KLink>
              .
            </p>
            <p className="text:mini color:moderate-weak">
              [1] Credit Score Increase: Based on Kikoff customers that used the
              {getCreditAccountMentalModelName()} product and made consistent
              on-time payments during account lifetime. This data is based on
              observed VantageScore 3.0 credit score changes. Payment behavior
              can have an impact on your credit score, and individual results
              may vary. Data current as of March 2022.
            </p>
          </>
        ),
        es: (
          <>
            <p>
              En promedio, los clientes con puntajes de crédito por debajo de
              600 aumentan sus puntajes de crédito en 58 puntos[1], lo que los
              ayuda a calificar para mejores préstamos para automóviles,
              hipotecas, tarjetas de crédito, préstamos personales y más.
            </p>
            <p>
              Tenga en cuenta que la creación de crédito es un hábito a largo
              plazo, como comer de manera saludable o hacer ejercicio, por lo
              que lo más importante es adoptar buenos hábitos financieros: haga
              sus pagos a tiempo, mantenga su utilización baja y{" "}
              <KLink href={blogUrl("/how-do-i-get-a-700-credit-score")}>
                practique buenos hábitos financieros.
              </KLink>
            </p>
            <p className="text:mini color:moderate-weak">
              [1] Aumento del puntaje de crédito: basado en los clientes de
              Kikoff que usaron el producto {getCreditAccountMentalModelName()}{" "}
              y realizaron pagos puntuales constantes durante la vigencia de la
              cuenta. Estos datos se basan en los cambios observados en la
              puntuación de crédito de VantageScore 3.0. El comportamiento de
              pago puede tener un impacto en su puntaje de crédito y los
              resultados individuales pueden variar. Datos actualizados a marzo
              de 2022.
            </p>
          </>
        ),
      },
    },
    {
      question: {
        en: "Which bureaus does Kikoff report to?",
        es: "¿A qué burós de crédito reporta Kikoff?",
      },
      answer: {
        en: (
          <>
            <p>
              Kikoff is an official data furnisher to the 3 major credit
              bureaus: Equifax, Experian, and TransUnion.
            </p>
            <p>
              Kikoff reports your accounts every month, at the start of the
              month. Please note we report to different bureaus, depending on
              which accounts you have:
              <ul>
                <li>
                  <b>{getCreditAccountMentalModelName()}</b> reports to Equifax,
                  Experian, and TransUnion.
                </li>
                <li>
                  The <b>Secured Credit Card</b> (optional add-on) reports to
                  Equifax, Experian and TransUnion. We require a minimum deposit
                  of $50 required to be reported.
                </li>
              </ul>
            </p>
          </>
        ),
        es: (
          <>
            <p>
              Kikoff es un proveedor oficial de datos de las 3 principales
              agencias de crédito: Equifax, Experian y TransUnion.
            </p>
            <p>
              Kikoff informa de tus cuentas todos los meses, a principios de
              mes. Tenga en cuenta que informamos a diferentes oficinas, según
              las cuentas que tenga:
              <ul>
                <li>
                  <b>{getCreditAccountMentalModelName()}</b> informes a Equifax
                  y Experian. Requerimos 1 pago exitoso antes de informar.
                </li>
                <li>
                  La <b>Tarjeta de crédito asegurada</b> (complemento opcional)
                  reporta a Equifax, Experian y TransUnion. Requerimos un
                  depósito mínimo de $50 requerido para ser informado.
                </li>
              </ul>
            </p>
          </>
        ),
      },
    },
    {
      question: {
        en: "Are there other fees?",
        es: "¿Hay otros cargos?",
      },
      answer: {
        en: (
          <>
            <ul>
              <li>
                With the <b>{getCreditAccountMentalModelName()}</b>, there are
                no additional fees. You are responsible for paying back any
                purchases financed by the{" "}
                {getKikoffCreditAccountMentalModelName()}, but there are no
                additional interest fees, late fees, or other fees.
              </li>
              <li>
                Using the <b>Secured Credit Card</b>, you can access your funds
                without any additional fees from over 55,000 ATMs in the Kikoff
                network conveniently located in Walgreens, Target, CVS, and
                more. Any ATMs outside of the Allpoints network will be subject
                to a $2.50 Kikoff fee in addition to any ATM fees.
              </li>
            </ul>
          </>
        ),
        es: (
          <>
            <p>Con Kikoff, no hay cargos adicionales.</p>
            <p>
              Usted es responsable de pagar cualquier compra financiada por{" "}
              {getKikoffCreditAccountMentalModelName()}, pero no hay cargos por
              intereses adicionales, cargos por pagos atrasados u otros cargos.
            </p>
            <ul>
              <li>
                Con <b>{getCreditAccountMentalModelName()}</b>, no hay cargos
                adicionales. Usted es responsable de pagar cualquier compra
                financiada por {getKikoffCreditAccountMentalModelName()}, pero
                no hay cargos por intereses adicionales, cargos por pagos
                atrasados u otros cargos.
              </li>
              <li>
                Con la <b>Tarjeta de crédito asegurada</b>, puede acceder a sus
                fondos sin cargos adicionales desde más de 55 000 cajeros
                automáticos de la red Kikoff convenientemente ubicados en
                Walgreens, Target, CVS y más. Cualquier cajero automático fuera
                de la red de Allpoints estará sujeto a una tarifa Kikoff de
                $2.50 además de cualquier tarifa de cajero automático.
              </li>
            </ul>
          </>
        ),
      },
    },
    {
      question: {
        en: "Is there a Kikoff app?",
        es: "¿Hay una aplicación Kikoff?",
      },
      answer: {
        en: (
          <>
            <p>
              Yes, we have both Android and iOS apps! We have tens of thousands
              of 5-star reviews.
            </p>
            <p>
              You can find our app by searching “Kikoff” on the Play Store and
              App Store. Our app is the best way to get updates and make sure
              you’re getting the best Kikoff experience.
            </p>
          </>
        ),
        es: (
          <>
            <p>¡Sí, tenemos aplicaciones para Android e iOS!</p>
            <p>
              Puede encontrarlo buscando “Kikoff” en Play Store y App Store.
              Nuestra aplicación es la mejor manera de obtener actualizaciones y
              asegurarse de que está obteniendo la mejor experiencia Kikoff.
            </p>
          </>
        ),
      },
    },
    {
      question: {
        en: "I haven’t received a physical card yet, where is my card?",
        es:
          "Todavía no he recibido una tarjeta física, ¿Dónde está mi tarjeta?",
      },
      answer: {
        en: (
          <>
            <p>
              Kikoff only sends a physical card if you have signed up for the{" "}
              <b>Secured Credit Card</b> product.
            </p>
            <p>
              There is not a physical card associated with the{" "}
              {getCreditAccountMentalModelName()}.
            </p>
          </>
        ),
        es: (
          <>
            <p>
              Kikoff solo envía una tarjeta física si te has dado de alta en el
              producto <b>Tarjeta de crédito asegurada</b>.
            </p>
            <p>
              No hay una tarjeta física asociada con{" "}
              {getCreditAccountMentalModelName()} o Credit Builder Loan.
            </p>
          </>
        ),
      },
    },
    {
      question: {
        en: "What can I buy with Kikoff?",
        es: "¿Qué puedo comprar con Kikoff?",
      },
      answer: {
        en: (
          <>
            <p>
              The {getKikoffCreditAccountMentalModelName()} allows you to
              finance one of our credit building plans, and is designed to build
              your credit by making low monthly payments over time.
            </p>
            <p>
              If you&apos;re interested in using Kikoff to purchase everyday
              items like groceries, gas, and shopping, then try the Secured
              Credit Card. It functions like a debit card, but builds credit
              with every transaction you make with it.{" "}
              <KLink href="/kikoff-card-signup">Learn more here.</KLink>
            </p>
          </>
        ),
        es: (
          <>
            <p>
              {getKikoffCreditAccountMentalModelName()} le permite financiar uno
              de nuestros planes de creación de crédito y está diseñado para
              construir su crédito mediante pagos mensuales bajos a lo largo del
              tiempo.
            </p>
            <p>
              Si está interesado en usar Kikoff para comprar artículos
              cotidianos como comestibles, gasolina y compras, pruebe la tarjeta
              de efectivo. Funciona como una tarjeta de débito, pero genera
              crédito con cada transacción que realiza con ella.{" "}
              <KLink href="/kikoff-card-signup">Aprende más aquí.</KLink>
            </p>
          </>
        ),
      },
    },
    {
      question: {
        en: "How does Kikoff help build my credit?",
        es: "¿Cómo ayuda Kikoff a construir mi crédito?",
      },
      answer: {
        en: (
          <>
            <p>
              Kikoff products address all the major factors of your credit
              score: payment history, credit utilization, account age, and
              credit mix. Together, these factors make up 90% of your credit
              score.
            </p>
            <p>
              <b> Payment history </b> (35%) is the single most important factor
              in your score. It looks at whether you’ve paid past credit bills
              on time. The {getCreditAccountMentalModelName()} contributes to
              your monthly payment history.
            </p>
            <p>
              <b> Credit Utilization </b> (30%) is how much credit you’re using
              divided by your credit limit. The lower this ratio, the better.
              Responsibly using your {getCreditAccountMentalModelName()} can
              help you maintain low credit utilization.
            </p>
            <p>
              <b> Account age </b> (15%) is the average age of all your credit
              accounts. The {getCreditAccountMentalModelName()} doesn’t have an
              expiration date, so keeping it open will continue to grow your
              credit.
            </p>
            <p>
              <b> Credit Mix </b> (10%) means you can manage different types of
              credit products, such as a car loan, credit card, student loan,
              installment loan, etc… Each Kikoff product helps diversify your
              credit mix.
            </p>
          </>
        ),
        es: (
          <>
            <p>
              Los productos Kikoff abordan todos los factores principales de su
              puntaje de crédito: historial de pagos, utilización del crédito,
              antigüedad de la cuenta y combinación de créditos. Juntos, estos
              factores constituyen el 90% de su puntaje de crédito.
            </p>
            <p>
              <b>Historial de pagos</b> (35 %) es el factor individual más
              importante en su puntuación. Analiza si ha pagado las facturas de
              crédito anteriores a tiempo. La cuenta de crédito Kikoff y el
              préstamo Credit Builder contribuyen a su historial de pago
              mensual.
            </p>
            <p>
              <b>Utilización de crédito</b> (30 %) es la cantidad de crédito que
              está utilizando dividida por su límite de crédito. Cuanto más baja
              sea esta relación, mejor. Los expertos recomiendan por debajo del
              10%. Los nuevos miembros en el inicio de Kikoff verán una
              utilización del 5 % en su cuenta de crédito (¡excelente!).
            </p>
            <p>
              <b>Edad de la cuenta</b> (15%) es la edad promedio de todas sus
              cuentas de crédito. La cuenta de crédito Kikoff nunca caduca, por
              lo que mantenerla abierta seguirá aumentando su crédito.
            </p>
            <p>
              <b>Combinación de créditos</b> (10 %) significa que puede
              administrar diferentes tipos de productos de crédito, como un
              préstamo para automóvil, una tarjeta de crédito, un préstamo para
              estudiantes, un préstamo a plazos, etc. Los miembros de Kikoff
              pueden tener hasta 2 tipos diferentes de cuentas para ayudar a su
              mezcla de crédito.
            </p>
          </>
        ),
      },
    },
    {
      question: {
        en: "How long will it take for Kikoff to appear on my credit report?",
        es: "¿Cuánto tardará Kikoff en aparecer en mi informe crediticio?",
      },
      answer: {
        en: (
          <>
            <p>
              It usually can take up to 6 weeks for Kikoff to show up on your
              credit report, depending on when in the month you signed up.
              Kikoff reports everyone’s payments at the end of every month.
              After that, it usually can take up to 2 weeks to be processed by
              the credit bureaus, and added to your credit report.
            </p>
            <p>
              Note: if you have multiple Kikoff products, you’ll see multiple
              Kikoff items on your credit report. Don’t worry, that’s helping
              you build more credit.
            </p>
          </>
        ),
        es: (
          <>
            <p>
              Kikoff puede tardar hasta 6 semanas en aparecer en su informe de
              crédito, según el momento del mes en que se registró. Kikoff
              informa los pagos de todos al final de cada mes. Después de eso,
              las agencias de crédito pueden tardar hasta 2 semanas en
              procesarlo y agregarlo a su informe de crédito.
            </p>
            <p>
              Los miembros de Kikoff reciben un informe de crédito gratuito
              todos los meses. Queremos ayudarlo a realizar un seguimiento de su
              progreso y ver exactamente cómo está ayudando Kikoff.
            </p>
            <p>
              Nota: si tiene varios productos Kikoff, verá varios artículos
              Kikoff en su informe de crédito. No se preocupe, eso lo está
              ayudando a generar más crédito.
            </p>
          </>
        ),
      },
    },
    {
      question: {
        en: "Why can I trust Kikoff?",
        es: "¿Por qué puedo confiar en Kikoff?",
      },
      answer: {
        en: (
          <>
            <p>
              We’ve helped hundreds of thousands of people build credit. We’re
              officially recognized by the major credit bureaus, and Silicon
              Valley’s biggest investors trust and back Kikoff.
            </p>
            <p>
              But don’t just take our word for it--look at all the positive
              reviews across social media and our mobile apps (iOS + Android).
            </p>
            <p>
              Kikoff is 100% committed to your data privacy and security. We
              always use bank-level encryption in all data transmissions. We use
              your data for identity verification with the credit bureaus, fraud
              prevention, and to comply with federal laws. We will never share
              or sell your data to third parties without explicit consent.
            </p>
            <p>
              We’ve experienced firsthand how scary credit building can be,
              especially for those who can’t afford to take chances with it. So
              we built a tool that strives to bring financial opportunity to
              everyone. We’ve been there, we know how it is, and we want to
              offer you something better.
            </p>
          </>
        ),
        es: (
          <>
            <p>
              Hemos ayudado a cientos de miles de personas a generar crédito.
              Somos reconocidos oficialmente por las principales agencias de
              crédito y los mayores inversores de Silicon Valley confían y
              respaldan a Kikoff.
            </p>
            <p>
              Pero no confíe solo en nuestra palabra: mire todas las críticas
              positivas en las redes sociales y nuestras aplicaciones móviles
              (iOS + Android).
            </p>
            <p>
              Kikoff está 100% comprometido con la privacidad y seguridad de tus
              datos. Siempre usamos encriptación a nivel de banco en todas las
              transmisiones de datos. Usamos sus datos para verificación de
              identidad con las agencias de crédito, prevención de fraude y para
              cumplir con las leyes federales. Nunca compartiremos ni venderemos
              sus datos a terceros sin su consentimiento explícito.
            </p>
            <p>
              Hemos experimentado de primera mano lo aterrador que puede ser la
              creación de crédito, especialmente para aquellos que no pueden
              permitirse el lujo de arriesgarse. Así que creamos una herramienta
              que se esfuerza por brindar oportunidades financieras a todos.
              Hemos estado allí, sabemos cómo es y queremos ofrecerte algo
              mejor.
            </p>
          </>
        ),
      },
    },
    {
      question: {
        en: "Is there a credit pull to apply?",
        es: "¿Hay una verificación de crédito para aplicar?",
      },
      answer: {
        en: (
          <>
            <p>Kikoff does NOT hard pull your credit.</p>
            <p>
              We believe in helping you build credit easily so you can reach
              your financial goals. We don’t hard pull your score, we just help
              you build it.
            </p>
          </>
        ),
        es: (
          <>
            <p>Kikoff NO tira de su crédito.</p>
            <p>
              Creemos en ayudarlo a generar crédito fácilmente para que pueda
              alcanzar sus metas financieras. No verificamos su puntaje, solo lo
              ayudamos a construirlo.
            </p>
          </>
        ),
      },
    },
    {
      question: {
        en: "What is credit utilization and how does it help me?",
        es: "¿Qué es la utilización del crédito y cómo me ayuda?",
      },
      answer: {
        en: (
          <>
            <p>
              Credit utilization refers to how much of your credit limit you use
              at any given time. You can figure out your credit utilization rate
              by dividing your total account balance by your total credit limit.
              We calculate this for you and you can see your credit utilization
              directly on your Kikoff Dashboard.
            </p>
            <p>
              Most experts recommend keeping your overall credit card
              utilization below 15%. Lower credit utilization rates suggest to
              creditors that you can use credit responsibly.
            </p>
            <p>
              New customers typically see less than 15% utilization on their{" "}
              {getKikoffCreditAccountMentalModelName()} (considered an excellent
              utilization rate!). Utilization will vary depending on your
              purchases. Paying off your {getCreditAccountMentalModelName()}{" "}
              balance will bring your utilization rate even lower over time.
            </p>
          </>
        ),
        es: (
          <>
            <p>
              La utilización del crédito se refiere a la cantidad de su límite
              de crédito que utiliza en un momento dado. Puede calcular su tasa
              de utilización de crédito dividiendo el saldo total de su cuenta
              por su límite de crédito total. Lo calculamos por usted y puede
              ver la utilización de su crédito directamente en su Panel de
              Kikoff.
            </p>
            <p>
              La mayoría de los expertos recomiendan mantener la utilización
              general de su tarjeta de crédito por debajo del 10%. Las tasas de
              utilización de crédito más bajas les sugieren a los acreedores que
              puede usar el crédito de manera responsable.
            </p>
            <p>
              Los nuevos miembros verán una utilización inferior al 15 % en su
              cuenta de crédito Kikoff (¡considerada una tasa de utilización
              excelente!). Pagar su membresía de Kikoff hará que su tasa de
              utilización sea aún más baja con el tiempo.
            </p>
          </>
        ),
      },
    },
    {
      question: {
        en: "What is payment history and how does it help me?",
        es: "¿Qué es el historial de pagos y cómo me ayuda?",
      },
      answer: {
        en: (
          <>
            <p>
              Payment history makes up 35% of your credit score. It looks at
              whether you’ve paid past credit bills on time. This is the single
              most important factor in your credit score.
            </p>
            <p>
              Kikoff reports your monthly payments to the major credit bureaus
              every month. The {getCreditAccountMentalModelName()} and Secured
              Credit Card both build your payment history--and your credit.
            </p>
          </>
        ),
        es: (
          <>
            <p>
              El historial de pagos representa el 35% de su puntaje de crédito.
              Analiza si ha pagado las facturas de crédito anteriores a tiempo.
              Este es el factor más importante en su puntaje de crédito.
            </p>
            <p>
              Kikoff informa sus pagos mensuales a las principales agencias de
              crédito todos los meses. Tanto la cuenta de crédito Kikoff como el
              préstamo Credit Builder construyen su historial de pagos y su
              crédito.
            </p>
          </>
        ),
      },
    },
    {
      question: {
        en: "In what states is Kikoff available?",
        es: "¿En qué estados está disponible Kikoff?",
      },
      answer: {
        en: (
          <>
            <p>Kikoff is available in all 50 states.</p>
          </>
        ),
        es: (
          <>
            <p>Kikoff está disponible en los 50 estados.</p>
          </>
        ),
      },
    },
    {
      question: {
        en: (
          <>
            <p>How do I use Kikoff&apos;s dispute tool?</p>
          </>
        ),
        es: "¿Cómo uso la herramienta de disputas de Kikoff?",
      },
      answer: {
        en: (
          <>
            <p>
              Kikoff&apos;s dispute tool is free to use for any person and does
              not require a paid plan to use. After making an account, you can
              access your credit report from Equifax for free and use the
              dispute tool to streamline flagging of any errors and send them to
              Equifax for review.
            </p>
          </>
        ),
        es: (
          <>
            <p>
              La herramienta de disputas de Kikoff es de uso gratuito para
              cualquier persona y no requiere un plan pago para su uso. Después
              de crear una cuenta, puede acceder a su informe crediticio de
              Equifax de forma gratuita y utilizar la herramienta de disputas
              para agilizar la señalización de cualquier error y enviarlo a
              Equifax para su revisión.
            </p>
          </>
        ),
      },
    },
    {
      question: {
        en: `Can I cancel my Kikoff ${getCreditAccountMentalModelName()}?`,
        es: `¿Puedo cancelar mi ${getCreditAccountMentalModelName()}?`,
      },
      answer: {
        en: (
          <>
            <p>
              Of course! We want you to be in control of your finances, and
              you’re welcome to cancel your{" "}
              {getKikoffCreditAccountMentalModelName()} at any time.
            </p>
            <p>
              Please note that if you’ve already made 1+ payments, you’ve been
              reported to the credit bureaus as a tradeline so closing your
              account will negatively impact your average account age.
            </p>
            <p>
              For that reason, we highly encourage you to keep your account open
              and continue making any payments due – this will boost your
              payment history and account age, both important factors in your
              credit score.
            </p>
          </>
        ),
        es: (
          <>
            <p>
              ¡Por supuesto! Queremos que tenga el control de sus finanzas y
              puede cancelar su {getKikoffCreditAccountMentalModelName()} en
              cualquier momento.
            </p>
            <p>
              Tenga en cuenta que si ya ha realizado más de 1 pago, se le ha
              informado a las agencias de crédito como una línea comercial, por
              lo que cerrar su cuenta tendrá un impacto negativo en la edad
              promedio de su cuenta.
            </p>
            <p>
              Por esa razón, le recomendamos encarecidamente que mantenga su
              cuenta abierta y continúe realizando los pagos vencidos; esto
              aumentará su historial de pagos y la antigüedad de la cuenta,
              ambos factores importantes en su puntaje crediticio.
            </p>
          </>
        ),
      },
    },
  ];
};

interface QuestionsSectionProps {
  showMore?: "link" | "expand" | "show";
  customQuestions?: {
    question: { en: ReactNode; es: ReactNode };
    answer: { en: ReactNode; es: ReactNode };
  }[];
}

const QuestionsSection: React.FC<QuestionsSectionProps> = ({
  showMore,
  customQuestions,
}) => {
  const localizedFaqs = customQuestions ?? faqs();
  const [expands, setExpands] = useState(() =>
    Array(localizedFaqs.length).fill(false)
  );
  const [more, setMore] = useState(showMore === "show");
  const questions = more ? localizedFaqs : localizedFaqs.slice(0, 5);
  const locale = getLocaleCookie();

  return (
    <section className={styles["questions-section"]}>
      <LocalizationProvider l10n={l10n}>
        <div className={styles["questions"]}>
          <header className="text:heading-3 mt-0">
            <Localized id="landing-faq-title">Have questions?</Localized>
          </header>
          <ul>
            {questions.map(({ question, answer }, i) => (
              <li key={i}>
                <ContainerButton
                  onClick={() => {
                    if (!expands[i])
                      track.tap("Landing - Questions - Expand Question", {
                        question: localizedFaqs[i].question[locale],
                      });
                    const arr = Array(localizedFaqs.length).fill(false);
                    arr[i] = !expands[i];
                    setExpands(arr);
                  }}
                  className={styles.question}
                  data-cy="faq-question"
                >
                  <div>{question[locale]}</div>
                  <PlusExpander expand={expands[i]} />
                </ContainerButton>
                <Collapsible collapse={!expands[i]}>
                  <div className={styles.answer} data-cy="faq-answer">
                    {answer[locale]}
                  </div>
                </Collapsible>
              </li>
            ))}
          </ul>
          {showMore === "expand" && !more && (
            <ContainerButton
              className={styles.expand}
              onClick={() => {
                setMore(true);
              }}
            >
              SEE MORE
            </ContainerButton>
          )}
        </div>
      </LocalizationProvider>
    </section>
  );
};

export default QuestionsSection;
