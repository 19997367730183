import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Router from "next/router";
import { RecordTuple } from "record-tuple";

import { PropsFromLazy } from "@kikoff/overlays/src/types";
import { web } from "@kikoff/proto/src/protos";

import { dismiss, selectDismissal, setPoppedByRoute } from "@feature/page";
import { Overlays, useOverlaysController } from "@src/overlay";
import { RootState } from "@store";

export enum PopupType {
  OVERLAY = "overlay",
  TAKEOVER = "takeover",
}

interface BasePopup {
  isEligible: boolean | null;
  dismissalTag?: keyof typeof web.public_.Dismissable.Name;
  dismissalToken?: string;
  isLoading?: boolean;
  onPop?: () => void;
}

interface TakeoverPopup extends BasePopup {
  popupType: PopupType.TAKEOVER;
  url: `/${string}`;
}

interface OverlayPopup<K extends keyof Overlays = keyof Overlays>
  extends BasePopup {
  popupType: PopupType.OVERLAY;
  name: K;
  params?: PropsFromLazy<Overlays[K]>;
}

export type Popup = OverlayPopup | TakeoverPopup;

export interface PopupGroup {
  isLoading: boolean;
  popups: Popup[];
}

type PopupItem = Popup | PopupGroup;

type UsePopupsProps = {
  popups: PopupItem[];
};

function isPopupGroup(item: PopupItem): item is PopupGroup {
  return "popups" in item && Array.isArray((item as PopupGroup).popups);
}

function flattenPopups(items: PopupItem[]): Popup[] {
  return items.flatMap((item) => (isPopupGroup(item) ? item.popups : item));
}
/**
 * Hook to manage the display of popups (overlays and takeovers) based on eligibility and dismissal status.
 *
 * @param props.popups - Array of Popups or PopupGroups. Popups are overlays or takeovers
 *
 * The hook will:
 * - Show the first eligible, non-dismissed popup when all popups are ready (isLoading is false and non-null eligibility)
 * - Track popup displays by route so only one popup is shown per route
 * - Handle dismissal logic
 * - Support both overlay and takeover popups
 * - Support grouped popups that share loading state, for dynamic generation of popups.
 *
 * @example
 * ```tsx
 * const MyComponent = () => {
 *   usePopups({
 *     popups: [
 *       // Single popup
 *       {
 *         popupType: PopupType.OVERLAY,
 *         name: '/shopping/post_purchase_survey/default',
 *         isEligible: true,
 *         dismissalTag: 'ATTRIBUTION_SURVEY',
 *         dismissalToken: 'SOME_DISMISSAL_TOKEN'
 *       },
 *       // Popup group
 *       {
 *         isGroupLoading: false,
 *         popups: [
 *           {
 *             popupType: PopupType.OVERLAY,
 *             name: 'some_overlay',
 *             isEligible: true,
 *             dismissalTag: 'SOME_DISMISSAL_TAG'
 *           }
 *         ]
 *       }
 *     ]
 *   });
 *
 *   return <div>My Component</div>;
 * };
 * ```
 */
export default function usePopups({ popups }: UsePopupsProps): void {
  const dispatch = useDispatch();
  const overlays = useOverlaysController();
  const currentRoute = Router.pathname;
  const poppedByRoute = useSelector(
    (state: RootState) => state.page.poppedByRoute
  );

  const handleDismissal = (popup: Popup) => {
    if (popup.dismissalTag) {
      dispatch(dismiss(popup.dismissalTag, popup.dismissalToken));
    }
  };

  const flattenedPopups = flattenPopups(popups);

  const dismissedByIdx = useSelector((state) =>
    flattenedPopups.map((popup) =>
      selectDismissal(popup.dismissalTag, popup.dismissalToken)(state)
    )
  );

  useEffect(() => {
    if (poppedByRoute[currentRoute]) return;

    // if isLoading is null, interpret as not loading
    const allPopupsReady = popups.every((item) => !(item.isLoading ?? false));

    if (!allPopupsReady) return;

    const popupToShow = flattenedPopups.find(
      (popup, index) => popup.isEligible && !dismissedByIdx[index]
    );

    if (popupToShow) {
      dispatch(setPoppedByRoute(currentRoute));

      switch (popupToShow.popupType) {
        case PopupType.TAKEOVER:
          Router.push(popupToShow.url).then(() => {
            handleDismissal(popupToShow);
            popupToShow.onPop?.();
          });
          break;
        case PopupType.OVERLAY:
          overlays.push(popupToShow.name, popupToShow.params).then(() => {
            handleDismissal(popupToShow);
            popupToShow.onPop?.();
          });
          break;
        default:
          console.error("Invalid popup type");
          break;
      }
    }
  }, [currentRoute, RecordTuple.deep(popups)]);
}
