// new event name -> old event name
export const DUPLICATED_EVENTS: Record<string, string> = {
  "View Page": "view page",
  "App Store Badge: Tap": "App Store Badge clicked",
  "Play Store Badge: Tap": "Play Store Badge clicked",
  "Google Sso - Button: Tap": "google sso: button clicked",
  "Google Sso - Created User": "google sso: create user",
  "Google Sso - Authenticated User": "google sso: authenticated user",
  "Google Sso - Failed Credentials": "google sso: could not verify credentials",
  "Refer - Copy Link: Tap": "refer: copied link",
  "Refer - Native Share: Tap": "refer: clicked native share",
  "Bank Verifier - Exit": "bank verifier: exited",
  "Account - Toggle Theme Button: Tap": "Toggle theme: Tap",
  "Payment Methods - Turn On Autopay: Tap":
    "payment: clicked add new from options",
  "Payment Methods - Remove Intent: Tap": "payment: click remove",
  "Account - Card Options - Close Account Button: Tap":
    "credit line: close account intention from manage card",
  "Payment Methods - Add New Button: Tap": "payment: clicked add new",
  "Payment Methods - Remove Payment Method Button: Tap":
    "payment: remove payment method",
  "Payment Methods - Re-Auth Plaid Payment Method: Tap":
    "payment: re-auth plaid payment method",
  "Payment Methods - Verify Button: Tap": "payment: click verify",
  "Dashboard - Credit Card Usage Card - Close Button: Tap":
    "Credit Card Usage Dashboard Card - closed",
  "Dashboard - Credit Card Usage Card - CTA: Tap":
    "Credit Card Usage Dashboard Card - action to card usage",
  "Freemium - Learn How Link: Tap": "freemium: learn how click",
  "Credit Score - Raw Report Card: Impression": "credit: raw report impression",
  "Credit Score - View Full Report Button: Tap":
    "credit: clicked view full report",
  "Disputes - Spot Inconsistencies Card: Tap":
    "click spot inconsistencies card",
  "Credit Score - Credit Changes - Entrypoint: Impression":
    "credit changes: entrypoint impression",
  "Credit Score - Credit Changes - Entrypoint CTA: Tap":
    "credit changes: entrypoint clicked",
  "Credit Score - Todo: Impression": "credit: todo impression",
  "Credit Score - Todo CTA: Tap": "credit: todo cta click",
  "Credit Score - CA Upsell Learn More Button: Tap": "credit: click upsell",
  "Credit Score - Download Raw Report: Tap":
    "download raw report button clicked",
  "Credit Score - Factor Dropdown Row: Tap":
    "credit: clicked factor dropdown row",
  "Credit Score - Send Feedback Button: Tap": "click feedback button",
  "CA - Dashboard - Authorized User - Completion: Impression":
    "authorized user: completion impression",
  "CA - Dashboard - Authorized User CTA: Tap":
    "authorized user: clicked primary cta",
  "CA - Dashboard - Authorized User Card: Impression":
    "authorized user: card impression",
  "CA - Premium Upsell Dismissible: Impression":
    "premium upsell dismissible: impression",
  "CA - Premium Upsell Dismissible - Learn More Button: Tap":
    "upgrade promo credit: click learn more",
  "Dashboard - CA Restart Upsell - Get Started Button: Tap":
    "Credit Account Restart - Get Started Button: Tap",
  "CA - Dashboard - Credit Line Info Button: Tap":
    "CA Details - Credit Line Info Button : Tap",
  "CA - Dashboard - Credit Utilization Info Button: Tap":
    "CA Details - Credit Utilization Info Button : Tap",
  "CA - Subscription - Toggle Autorenew Overlay: Impression":
    "subscription: display auto-renew toggle modal",
  "CA - Subscription - Downgrade Intention Button: Tap":
    "credit line: downgrade intention",
  "CBL - Account Details - Toggle Collapse: Tap":
    "cbs account details: toggle collapse",
  "CBL - Dashboard - Close Loan Button: Tap":
    "credit builder loan: close loan intention",
  "CBL - Documents - Open Loan Agreement Button: Tap":
    "cbs transaction history: open loan agreement modal",
  "CBL - Payment Method - Toggle Collapse: Tap":
    "cbs payment method: toggle collapse",
  "CBL - Transaction History - Toggle Collapse: Tap":
    "cbs transaction history: toggle collapse",
  "CA - Subscription - Premium Upsell Checkout - Confirm: Tap":
    "premium upsell checkout: click confirm",
  "CA - Subscription - Premium Upsell Checkout - Success":
    "premium upsell checkout: upgrade success",
  "CA - Subscription - Plan Change - Confirm: Tap":
    "premium subsription downgrade: on cool down",
  "CA - Subscription - Plan Change - Overdue Account Error":
    "premium subsription downgrade: account overdue",
  "CA - Subscription - Plan Change - Upgrade Promo Applied":
    "subscription plan change: upgrade promo applied",
  "CA - Subscription - Plan Change - Success":
    "subscription plan change: plan change success",
  "CA - Premium Upsell Card - Continue: Tap":
    "premium upsell card: click continue",
  "CA - Premium Upsell Card: Impression": "3B Premium Upsell Card: Impression",
  "CA - Premium Upsell Card - Learn More Button: Tap":
    "creditline promo: click learn more",
  "Dashboard - CA Card: Impression": "Credit Line Card: Impression",
  "Dashboard - CA Card - Pay While Paused Button: Tap":
    "credit line: pay while paused impression",
  "Dashboard - Download App Card: Impression":
    "Home Dashboard - Download App Card: Impression",
  "Dashboard - Download App Card - App Store Button: Tap":
    "Home Dashboard - Download App Card - App Store Button: Tap",
  "Dashboard - Download App Card - Play Store Button: Tap":
    "Home Dashboard - Download App Card - Play Store Button: Tap",
  "Dashboard - Download App Card - CTA: Tap":
    "Home Dashboard - Download App Card - CTA: Tap",
  "Dashboard - Feedback - Select Rating: Tap": "feedback: selected rating",
  "Dashboard - Feedback - Submitted": "feedback: submitted",
  "Dashboard - Refer Card - Share Button": "refer: clicked share button",
  "Dashboard - Refer Card: Impression": "Refer Card: Impression",
  "Dashboard - SC Upsell Banner - Exit: Tap":
    "secured card upsell banner: exit",
  "Dashboard - SC Upsell Banner - CTA: Tap":
    "secured card upsell banner: tap cta",
  "Store - View Payment Schedule CTA: Tap": "payment: view payment schedule",
  "Onboarding - Call Us Button: Impression":
    "onboarding: Call us button appeared: Impression",
  "Onboarding - Call Us Button: Tap": "onboarding: Call us button clicked: Tap",
  "CA - Review Autorenew - Toggle Switch: Tap":
    "review autorenew: switch toggled",
  "Dashboard - Site Tour - Close: Tap": "tour: complete",
  "Dashboard - Site Tour - Step": "tour: step",
  "Debt Settlement - CTA: Tap": "debt eligible accounts: click action",
  "Chatbot - Help Chat Button: Tap": "chatbot: help chat button: Tap",
  "Persona - Inquiry Event": "persona: inquiry event",
  "Onboarding - Address - Google Maps Query Failed":
    "error: received non-OK places service status",
  "Account - Use Sublocality For City": "account: use sublocality for city",
  "Account - Toggle Locale Button: Tap": "Toggle locale: Tap",
  "Landing - Secured Card - Sign Up Button: Tap":
    "cash card signup landing page: CTA",
  "Landing - Secured Card Hero - CTA: Tap":
    "get started clicked: secured card hero: Tap",
  "Landing - SC Waitlist - Join Button: Tap": "waitlist: click join",
  "Landing - Disputes FAQ Title: Tap": "disputes-faq: click title",
  "Landing - Signup - Submit Email: Tap":
    "signup: submitted landing page email",
  "Landing - Intro Promo Banner: Impression":
    "Homepage - Intro Promo Banner: Impression",
  "Landing - Intro Promo Banner - Redeem Offer: Tap":
    "Homepage - Intro Promo Banner - Redeem offer: Tap",
  "Landing - Members Helped - CTA: Tap": "members helped: clicked desktop cta",
  "Landing - Questions - Expand Question: Tap": "faq: click question",
  "Footer - Expand NM Legal Text: Tap": "expanded NM legal text",
  "Footer - Get Started Button: Tap": "footer: click ",
  "Footer - Affiliate Link: Tap": "footer: click ",
  "Landing - Signup - Sticky Signup Bar: Tap":
    "signup: clicked sticky signup bar",
  "SC - Atomic Fi - Atomic Complete": "Secured Card Web - Atomic complete",
  "SC - Atomic Fi - Atomic Closed": "Secured Card Web - Atomic closed",
  "CA - Payment - Autopay Toggle Button: Tap": "autopay: autopay toggled",
  "Auth - Login Banner - Get Help Button: Tap":
    "login banner: get help link clicked",
  "Onboarding - Address Suggested": "onboarding: address suggested",
  "Onboarding - Submit Phone - Phone Taken": "error: phone taken",
  "ITIN Interest Experiment - What: Tap": "itin interest experiment: what",
  "Onboarding - Address Selected": "onboarding: address selected",
  "CBL - Pay Loan - Pay Now Button: Tap": "loan: clicked pay now",
  "CBL - Pay Loan - Retry Payment": "credit line: retry payment",
  "Dashboard - Rent Reporting Card: Impression":
    "Rent Reporting Card: Impression",
  "Promos - Intro Promo Poller: Impression":
    "User is eligible for Intro Promo: Impression",
  "Navigation Bar": "navigation bar impression",
  "Overlay: Push": "overlay: push",
  "Bill Reporting - Home - Stop Reporting Button: Tap":
    "bill reporting home - Stop Reporting Button: Tap",
  "Bill Reporting - Home - Switch Provider Button: Tap":
    "bill reporting home - Switch Provider Button: Tap",
  // "Bill Reporting - Dashboard Card: Impression":
  //   "bill reporting dashboard upsell card: Impression",
  "Debt Relief - External Bank Transfer Overlay - Exit: Tap":
    "debt reliev: closed external bank transfer popup",
  "SC - Onboarding - Direct Deposit - Email Sent":
    "secured card onboarding - dd email sent",
  "SC - Onboarding - Direct Deposit - Email Failed":
    "secured card onboarding - dd email failed",
  "SC - Onboarding - Atomic Public Token Failed":
    "secured card onboarding - atomic public token failed",
  "SC - Onboarding - Atomic Complete":
    "secured card onboarding - atomic complete",
  "SC - Onboarding - Atomic Closed": "secured card onboarding - atomic closed",
  "SC - Signup Modal - Sign Up Button: Tap": "Cash Card Signup CTA",
  "SC - Onboarding - Update Address Submit: Tap":
    "secured card onboarding: update address saved",
  "SC - Cash Card Upsell From Virtual Card: Impression":
    "impression: upsell from virtual card",
  "Credit Insights - Goal - Submit Button: Tap": "credit insights: submit",
  "CA - Dashboard - Change Autopay Overlay - CTA: Tap":
    "schedule autopay: click change date cta",
  "CA - Pause Membership - Screen: Impression": "Credit Account Pause - Paused",
  "CA - Subscription - Pay Before Pause Overlay - Pay Then Pause: Tap":
    "pay before pause impression: pay and then pause: Tap",
  "CA - Payment - Submit Payment: Tap": "credit line: clicked submit payment",
  "CA - Payment - Partial Payment Upsell - CTA: Tap":
    "payment: Partial Payment click: Tap",
  "Debt Negotiations - Upsell - Overlay CTA: Tap":
    "debt negotiations upsell: overlay cta clicked: Tap",
  "Debt Negotiations - Upsell - Upgraded": "debt negotiations upsell: upgraded",
  "Tax Filing - Premium Benefits Overlay - Continue: Tap":
    "tax filing: premium benefits - clicked continue",
  "CA - Subscription - Upgrade Plan Overlay - Continue Button: Tap":
    "premium or ultimate upgrade plan overlay: click continue",
  "Datetime Overlay - Select Date Overlay - Close Button: Tap":
    "bucket onboarding: user dismissed overlay for choosing first payment day",
  "Debt Settlement - What If Payment Missed Offer Overlay: Impression":
    "debt settlement: what if payment missed bottom sheet shown",
  "Debt Settlement - Why Can: Impression": "debt settlement: why can",
  "Disputes - Premium Upsell Checkout - Confirm Button: Tap":
    "disputes premium upsell checkout: click confirm",
  "Disputes - Premium Upsell Checkout - Upgrade Success":
    "disputes premium upsell checkout: upgrade success",
  "Auth - Forgot Login - Change Email Button: Tap":
    "forgot login: change email",
  "Auth - Forgot Login - Email Taken": "forgot login: email taken",
  "Account - Change Phone": "account: change phone",
  "Auth - Forgot Login - Phone Taken": "error: phone taken",
  "Survey - Open": "survey: open",
  "Survey - Submit: Tap": "survey: submit",
  "Survey - Exit: Tap": "survey: exit",
  "Instatouch Verification - Success": "instatouch:pii verified",
  "Instatouch Verification - Error - Unable to Get User PII":
    "instatouch:unable to get user pii",
  "Instatouch Verification - Failed": "instatouch:verification failed",
  "MFA - Select Alternative Carrier: Tap": "MFA - Select Alternative Carrier",
  "MFA - Verify Button: Tap": "MFA - Verify Button",
  "MFA - Screen: Impression": "MFA - Screen",
  "MFA - Log Out Button: Tap": "MFA - Log Out Button",
  "MFA - Resend Code Button: Tap": "MFA - Resend Code Button",
  "Onboarding - IDV - Retry PII: Tap": "retry pii clicked",
  "SC - Carousel - Agree to Terms Button: Tap":
    "cash account: clicked agree to terms",
  "SC - Carousel - Signup CTA: Tap": "Kikoff Banking: Cash Card Signup CTA",
  "SC - Carousel - Get Started Button: Tap":
    "cash account: carousel CTA clicked",
  "SC - Onboarding Upsell Modal - Exit: Tap": "Kikoff Banking: Modal Exit",
  "SC - Onboarding Upsell Modal - Back: Tap": "Kikoff Banking: Modal Back",
  "SC - Onboarding Upsell Modal - Get Started Button: Tap":
    "Kikoff Banking: Intro CTA",
  "SC - Onboarding Upsell Modal - Service Selection":
    "Kikoff Banking: Service Selection",
  "Freemium - Pop-up CTA: Tap": "freemium: pop-up continue clicked",
  "Landing - SC Waitlist - Submit Email": "waitlist: submit email",
  "ITIN Interest Experiment - How Do I Apply Link: Tap":
    "itin interest experiment: how do I apply link clicked",
  "ITIN Interest Experiment - ITIN Submitted":
    "itin interest experiment: itin submitted",
  "ITIN Interest Experiment - Learn More Link: Tap":
    "itin interest experiment: learn more link clicked",
  "Payment Methods - Unfreeze Payment Method: Tap":
    "payment: unfreeze payment method",
  "Rent Reporting - Manual Selection - View Details Button: Tap":
    "rent reporting manual selection: View Details - click: Tap",
  "Rent Reporting - Manual Selection - Yes I Paid Button: Tap":
    "rent reporting manual selection: Yes I paid - click: Tap",
  "Default Post Purchase Survey: Impression":
    "Default post purchase survey shown",
  "Default Post Purchase Survey - Submitted Survey Question":
    "submitted survey question",
  "Store - Switch Item: Tap": "shop: switch item",
  "Store - Review Order: Tap": "shop: clicked review",
  "Store - Submit Product Rating: Tap": "shopping: submitted product rating",
  "CA - Subscription - Toggle Autorenew Overlay - CTA: Tap":
    "subscription: toggle auto-renew",
  "Store - Warning: Impression": "CA Store - Warning - Impression",
  "Store - Warning - Learn More Button: Tap":
    "CA Store - Warning - Learn More Button",
  "Store - Warning - Go Back Button: Tap":
    "CA Store - Warning - Go Back Button",
  "Store - Warning - Continue Button: Tap":
    "CA Store - Warning - Continue Button",
  "Account - Change Address - Failed":
    "account: change address - update failed",
  "Account - Change Address - Submit Button: Tap":
    "account: change address - submit",
  "Account - Change Address - Verify With Persona Button: Tap":
    "account: change address - persona clicked to verify",
  "Account - Change Email: Tap": "account: change email",
  "Account - Change Email - Email Taken": "error: email taken",
  "Account - Change Password": "account: change password",
  "Account - Change Phone - Phone Taken": "error: phone taken",
  "CA - Close - Account Closed":
    "United Account Closure - ca - reason chosen and account closed: Tap",
  "CA - Close - Credit Service Canceled":
    "United Account Closure - ca - credit service canceled with CA kept open: Tap",
  "CA - Close - Closure Terms Confirmed: Tap":
    "United Account Closure - ca - terms confirmed: Tap",
  // "CBL - Close - Account Closed":
  //   "United Account Closure - cbl - reason chosen and account closed: Tap",
  "CBL - Close - Confirm Closure Terms: Tap":
    "United Account Closure - cbl - terms confirmed: Tap",
  "CBL - Close - Confirm Closure Withdraw: Tap":
    "United Account Closure - cbl - withdraw confirmed: Tap",
  "CBL - Close - Skip Closure Withdraw: Tap":
    "United Account Closure - cbl - withdraw skipped: Tap",
  "CBL - Close - Initiate Closure Transfer: Tap":
    "United Account Closure - cbl - transfer initiated: Tap",
  "SC - Close - Closure ACH Final Transfer Done: Tap":
    "United Account Closure - sc - ach final transfer done: Tap",
  "SC - Close - Skip Closure ACH Transfer: Tap":
    "United Account Closure - sc - ach transfer skipped: Tap",
  "SC - Close - Confirm Closure Terms: Tap":
    "United Account Closure - sc - terms confirmed: Tap",
  "SC - Close - Account Closed":
    "United Account Closure - sc - reason chosen and account closed: Tap",
  "404 - Return Home Button: Tap": "404: click return home",
  "Route Change Error": "route change error",
  "Disputes - Landing Page - Find Out Now Button: Tap":
    "disputes landing page: clicked find-out-now",
  "Disputes - Landing Page - Get Started Button: Tap":
    "disputes landing page: clicked get-started",
  "Landing - V2 - Rent Reporting Hero - CTA: Tap":
    "get started clicked: rent reporting hero: Tap",
  "Rent Reporting - How It Works - CTA: Tap":
    "get started clicked: rent reporting how it works: Tap",
  "Rent Reporting - Learn More: Tap":
    "learn more clicked: rent reporting what else: Tap",
  "SC - Signup Screen: Impression": "cash card signup - pageview",
  "Get Help - Kikoff AI Assistant Screen: Impression":
    "Kikoff AI Assistant: page impression",
  "Chatbot - Widget: Impression": "chatbot: widget shown",
  "Chatbot - Initiate Success": "chatbot: initiated",
  "Chatbot - Initiate Error": "chatbot: initiation error",
  "Chatbot - Landing Screen: Impression": "chatbot: page impression",
  "Account - Close Accounts Button: Tap":
    "United Account Closure - Close Accounts clicked: Tap",
  "Account - Resend Confirmation Email Button: Tap":
    "account: resend confirmation email",
  "Get Help - Help Center: Tap": "Get Help - Help Center Click: Tap",
  "Get Help - AI Kikoff Bot: Tap": "Get Help - AI Kikoff Bot Click: Tap",
  "CA - Close - Downgrade Screen: Impression":
    "united account closure: page impression: Impression",
  // "CA - Subscription - Downgrade Intention Button: Tap": "united account closure: downgrade clicked: Tap",
  "CA - Close - Cancel Membership Button: Tap":
    "united account closure: cancel clicked: Tap",
  "CBL - Close - Close Button: Tap":
    "United Account Closure - cbl - close clicked: Tap",
  "SC - Close - Close Account Intent Button: Tap":
    "United Account Closure - sc - close clicked: Tap",
  "Bill Reporting - Home - Add Provider Button: Tap":
    "bill reporting home - Add Provider Button: Tap",
  "Bill Reporting - Home - Edit Provider Button: Tap":
    "bill reporting home - Edit Provider Button: Tap",
  "Bill Reporting - Onboarding - Done for Now Button: Tap":
    "bill reporting onboarding - Done for Now Button: Tap",
  "Bill Reporting - Onboarding - View Dashboard Button: Tap":
    "bill reporting onboarding - View Dashboard Button: Tap",
  "Bill Reporting - Onboarding - Add More Bills Button: Tap":
    "bill reporting onboarding - Add More Bills Button: Tap",
  "Bill Reporting - Onboarding - Done Button: Tap":
    "bill reporting onboarding - Done Button: Tap",
  "Bill Reporting - Onboarding - No Verified Banks View: Impression":
    "bill reporting onboarding - No Verified Banks View: Impression",
  "Bill Reporting - Onboarding - Finish Button: Tap":
    "bill reporting onboarding - Finish Button: Tap",
  "Bill Reporting - Onboarding - Select Transaction":
    "bill reporting onboarding - Select Transaction",
  "Bill Reporting - Onboarding - Update Address Button: Tap":
    "bill reporting onboarding - Update Address Button: Tap",
  "Bill Reporting - Onboarding - Link Another Account Button: Tap":
    "bill reporting onboarding - Link Another Account Button: Tap",
  "Bill Reporting - Onboarding - Get Help Button: Tap":
    "bill reporting onboarding - Get Help Button: Tap",
  "Bill Reporting - Onboarding - Upload Bank Statement Button: Tap":
    "bill reporting onboarding - Upload Bank Statement Button: Tap",
  "Bill Reporting - Onboarding - No Plaid Address View: Impression":
    "bill reporting onboarding - No Plaid Address View: Impression",
  "Bill Reporting - Onboarding - Upgrade Failure":
    "bill reporting onboarding - Upgrade Failure",
  "Bill Reporting - Onboarding - Upgrade Success":
    "bill reporting onboarding - Upgrade Success",
  "Bill Reporting - Onboarding - Bank Statement Verification Failed View: Impression":
    "bill reporting onboarding - Bank Statement Verification Failed View: Impression",
  "Bill Reporting - Onboarding - Continue Button: Tap":
    "bill reporting onboarding - Continue Button: Tap",
  "Bill Reporting - Landing - Terms and Conditions: Tap":
    "bill reporting landing - Terms and Conditions: Tap",
  "Bill Reporting - Landing - Privacy Policy: Tap":
    "bill reporting landing - Privacy Policy: Tap",
  "Bill Reporting - Landing - Get Started Button: Tap":
    "bill reporting landing - Get Started Button: Tap",
  "Bill Reporting - Landing Screen: Impression":
    "bill reporting landing screen: Impression",
  "Bill Reporting - Dashboard - Go to Home Button: Tap":
    "bill reporting dashboard - Go to Home",
  "Bill Reporting - Dashboard Card: Impression":
    "bill reporting dashboard card: Impression",
  "Bill Reporting - Dashboard Card - Get Started Button: Tap":
    "bill reporting dashboard card - Get started: Tap",
  "SC - Premium Upsell - CTA: Tap": "sc premium upsell: clicked cta",
  "SC - Premium Upsell - Upgraded to Premium": "sc premium upsell: upgraded",
  "SC - Premium Upsell: Impression": "sc premium upsell: impression",
  "Dashboard - SC Manage Card Screen: Impression":
    "Dashboard - Manage Card Page: Impression",
  "Dashboard - SC Offer Screen: Impression":
    "Dashboard - Cash Card Offer Page: Impression",
  "Dashboard - SC Manage Card CTA: Tap":
    "dashboard - cash card - manage card CTA",
  "CA - Dashboard - Orders - Courses - Video Title Button: Tap":
    "courses: clicked video",
  "CA - Dashboard - Statements - Statement Details Button: Tap":
    "credit line: view statement",
  "CBL - Close - Selected Closure Reason":
    "credit builder loan: selected closure reason",
  "CBL - Close - Account Closed": "credit builder loan: closed loan",
  "CBL - Close - Account Closed Failed":
    "credit builder loan: closed loan failed",
  "CBL - Withdraw Voucher Funds - How Do I Unlock Button: Tap":
    "cbs voucher: open how to unloack modal",
  "CBL - Withdraw Voucher Funds - Exit: Tap":
    "withdraw voucher funds: clicked close",
  "CBL - Withdraw Voucher Funds - Ineligible Withdrawal - Return To Dashboard: Tap":
    "withdraw voucher funds: return to dashboard from ineligible withdrawal",
  "CBL - Withdraw Voucher Funds - Select Bank Account - Continue: Tap":
    "withdraw voucher funds: continue from select bank account",
  "CBL - Withdraw Voucher Funds - Confirm Withdrawal Button: Tap":
    "withdraw voucher funds: confirm withdrawal",
  "Credit Insights - Helpful Footer - Yes Button: Tap":
    "credit insights: page clicked primary cta",
  "Credit Insights - Helpful Footer - Not Quite Button: Tap":
    "credit insights: page clicked secondary cta",
  "Credit Insights - Problem Accounts - Problem Account Card: Tap":
    "credit insights: clicked problem account card",
  "Credit Insights - Screen: Impression": "credit insights: page impression",
  "Credit Insights - Loan Credit Goal CTA: Tap":
    "credit insights: clicked credit goal cta",
  "Credit Insights - Loan Credit Goal Completed":
    "credit insights: completed credit goal cta",
  "Credit Insights - New Tradeline - Tradeline Card: Tap":
    "credit insights: clicked tradeline card",
  "Credit Insights - Stacked Card: Impression":
    "credit insights: stacked card impression",
  "Credit Insights - Stacked Card CTA: Tap":
    "credit insights: stacked card clicked primary cta",
  "Credit Insights - Strong Start - Premium Upsell CTA: Tap":
    "credit insights: clicked premium upsell cta",
  "Credit Score - Freemium: Impression": "credit: view freemium",
  "Credit Score - CA Upsell CTA: Tap": "credit score: credit line upsell",
  "Credit Score - Credit Factor Video - Play: Tap":
    "Credit Factor Video - Clicked",
  "Credit Score - Account - Not Found": "account page: account not found",
  "Disputes - Credit Score Account - Disputes Prompt: Impression":
    "disputes: account prompt impression",
  "Disputes - Credit Score Account - Disputes Prompt CTA: Tap":
    "disputes: click account prompt cta",
  "Debt Relief - CTA: Tap": "debt relief: click action",
  "Disputes - CTA: Tap": "disputes: click action",
  "Tax Filing - Credit Dashboard Card: Tap":
    "tax filing: card on credit dashboard - clicked",
  "Credit Score - Credit Changes - Factor Entrypoint: Tap":
    "credit changes: factor entrypoint clicked",
  "Credit Score - Credit Changes - Account Entrypoint: Tap":
    "credit changes: account entrypoint clicked",
  "Credit Score - Credit Changes - Credit Change Details: Tap":
    "credit changes: change clicked",
  "Disputes - Download Letter: Tap": "disputes: download letter",
  "3B Disputes - Pull 3B Data For 3B Disputes Review":
    "3b Disputes: Pull 3b Data For 3b Disputes Review",
  "Disputes - Survey - Entrypoint CTA: Tap": "dispute survey: enter survey",
  "Credit Score - Credit Age Factor Screen: Impression":
    "Credit Age Overview: Impression",
  "Credit Score - Full Report - Hard Inquiries - Learn More Button: Tap":
    "factors: clicked learn more",
  "Credit Score - Factors List: Impression": "credit: view factors list",
  "Credit Score - Full Report - Disputes: Impression":
    "account report disputes entrypoint: impression",
  "Credit Score - Full Report - Disputes CTA: Tap":
    "account report disputes entrypoint: clicked",
  "Credit Score - Full Report - Disputes Banner: Impression":
    "full report disputes banner: impression",
  "Credit Score - Full Report - Disputes Banner CTA: Tap":
    "full report disputes banner: clicked",
  "Credit Score - Full Report - Table Info: Tap":
    "full report - user clicked table info",
  "Credit Score - Full Report - Inquiries: Impression":
    "Full Report - Inquiries: Impression",
  "Recommendations - Page Primary CTA: Tap":
    "recommendations: page clicked primary cta",
  "Recommendations - Page Secondary CTA: Tap":
    "recommendations: page clicked secondary cta",
  "Recommendations - Card: Impression": "recommendations: card impression",
  "Recommendations - Card Primary CTA: Tap":
    "recommendations: card clicked primary cta",
  "Recommendations - Card Secondary CTA: Tap":
    "recommendations: card clicked secondary cta",
  "Recommendations - Page Dispute Now Button: Tap":
    "recommendations: page clicked dispute now",
  "Credit Score - Credit Alerts Entrypoint: Impression":
    "credit alerts: entrypoint impression",
  "Credit Score - Credit Alerts Entrypoint CTA: Tap":
    "credit alerts: entrypoint clicked",
  "Credit Score - Credit Alerts - View Credit Changes Button":
    "credit alerts: page clicked view credit changes",
  "Credit Score - Credit Alerts - Dispute Now Button: Tap":
    "credit alerts: page clicked dispute now",
  "Credit Score - Credit Alerts - Website Button":
    "credit alerts: page clicked website",
  "Credit Score - Credit Alerts - Phone Number: Tap":
    "credit alerts: page clicked phone",
  "Credit Score - Credit Alerts - Alert Tile: Impression":
    "credit alerts: alert impression",
  "Credit Score - Credit Alerts - See Alert Details: Tap":
    "credit alerts: alert clicked",
  "Disputes - Premium Option: Impression":
    "disputes: premium option impression",
  "Disputes - Delivery - Next Button: Tap": "disputes: continue with delivery",
  "Disputes - Drawer - CTA: Tap": "Dispute Drawer CTA clicked",
  "Disputes - Survey - Closing Screen - Exit: Tap":
    "dispute survey: closing page close survey",
  "Disputes - Survey - Custom Response Screen - Next Button: Tap":
    "dispute survey: custom response page complete response",
  "Disputes - Survey - Custom Response Screen - Skip Button: Tap":
    "dispute survey: custom response page skip response",
  "Disputes - Survey - Letter Date Screen - Exit: Tap":
    "dispute survey: letter date page close survey",
  "Disputes - Survey - Letter Date Screen - No Bureau Response: Tap":
    "dispute survey: letter date page with no bureau response",
  "Disputes - Survey - Letter Date Screen - Yes Bureau Response: Tap":
    "dispute survey: letter date page with bureau response",
  "Disputes - Survey - Option Screen - Select First Four Options: Tap":
    "dispute survey: option page select first four options",
  "Disputes - Survey - Option Screen - Select Last Two Options: Tap":
    "dispute survey: option page select last two options",
  "Disputes - Dispute Deleted Overlay - OK Got It Button: Tap":
    "dispute item removed: ok got it",
  "Disputes - Dispute Deleted Overlay - Still On Report Button: Tap":
    "dispute item removed: still on report",
  "Disputes - Context": "disputes: context",
  "Disputes - Start": "disputes: start",
  "Disputes - Toggle Item: Tap": "disputes: toggle item",
  "Disputes - Change Reason: Tap": "disputes: change reason",
  "Disputes - Custom Reason Entered": "disputes: custom reason entered",
  "Debt Relief - Onboarding - Verify With Persona: Tap":
    "debt relief onboarding - persona verify clicked",
  "Debt Relief - Onboarding - Redirected to Persona":
    "debt relief onboarding - redirected to persona",
  "Debt Relief - FAQ Info Button: Tap":
    "debt relief: intro butotn clicked to read FAQ",
  "Dashboard - Dispute Upsell Takeover: Impression":
    "dispute upsell: takeover impression",
  "Dashboard - Dispute Upsell Takeover - CTA: Tap":
    "dispute upsell: takeover clicked primary cta",
  "Dashboard - Dispute Upsell Takeover - Exit: Tap":
    "dispute upsell: takeover dismissed",
  "Hello Privacy - Enrollment Upsell Page - Start Removing Button: Tap":
    "Hello Privacy - start removing button: Tap",
  "Debt Negotiations - Accounts Card - Account: Impression":
    "debt negotiations: accounts card show account",
  "Debt Negotiations - Accounts Card: Impression":
    "debt negotiations: show accounts card",
  "Debt Negotiations - Accounts Card - View Breakdown: Tap":
    "debt negotiations: accounts card clicked view breakdown",
  "Debt Negotiations - Accounts Card - See All Past Requests: Tap":
    "debt negotiations: accounts card clicked see all past requests",
  "Debt Negotiations - Accounts Card - Account CTA: Tap":
    "debt negotiations: accounts card clicked account",
  "Debt Negotiations - Intro Card: Impression":
    "debt negotiations: show intro card",
  "Debt Negotiations - Intro Card - Negotiate Now: Tap":
    "debt negotiations: intro card clicked negotiate now",
  "Debt Negotiations - Intro Card - View Breakdown: Tap":
    "debt negotiations: intro card clicked view breakdown",
  "Debt Negotiations - No To Collectors Painted Door - Card: Impression":
    "No To Collectors Painted Door - Card: Impression",
  "Debt Negotiations - No To Collectors Painted Door - Card CTA: Tap":
    "No To Collectors Painted Door - Card CTA: Tap",
  "Debt Negotiations - Painted Door - Card: Impression":
    "Debt Negotiations Painted Door - Card: Impression",
  "Debt Negotiations - Painted Door - Card - CTA: Tap":
    "Debt Negotiations Painted Door - Card - CTA: Tap",
  "Debt Negotiations - Cancel Negotiation: Tap":
    "debt negotiations: cancel negotiation",
  "Debt Negotiations - Mark Offer Paid: Tap":
    "debt negotiations: mark offer paid",
  "Debt Negotiations - Negotiation Screen: Impression":
    "debt negotiations: negotiation page impression",
  "Debt Negotiations - Negotiations - Account: Impression":
    "debt negotiations: negotiations page show account",
  "Debt Negotiations - Negotiations - Account CTA: Tap":
    "debt negotiations: negotiations page clicked account",
  "Debt Negotiations - Painted Door - Screen - CTA: Tap":
    "Debt Negotiations Painted Door - Screen - CTA",
  "Debt Negotiations - Debt Negotiation Initiated":
    "debt negotiations: debt negotiation initiated",
  "Debt Negotiations - Terms Agreed: Tap":
    "debt negotiations: terms agreed: Tap",
  "Debt Negotiations - Upsell - CTA: Tap":
    "debt negotiations upsell: page cta clicked: Tap",
  "Debt Negotiations - Takeover - Screen - CTA: Tap":
    "Debt Negotiations Takeover - Screen - CTA: Tap",
  "Debt Negotiations - Takeover - Screen: Impression":
    "Debt Negotiations Takeover - Screen: Impression",
  "Debt Negotiations - Takeover - Screen - Dismiss: Tap":
    "Debt Negotiations Takeover - Screen - Dismiss: Tap",
  "Bill Negotiations - Join Waitlist Button: Tap":
    "bill negotiations: join waitlist",
  "SC - Onboarding - Direct Deposit - Start Button: Tap":
    "secured card onboarding - start direct deposit",
  "SC - Onboarding - Direct Deposit - Skip Button: Tap":
    "secured card onboarding - skip direct deposit",
  "SC - Onboarding - Account Created":
    "secured card onboarding - account created",
  "SC - Onboarding - Persona Address Verificaton Required":
    "secured card onboarding - persona address verificaton required",
  "SC - Onboarding - Address Confirm CTA: Tap":
    "secured card onboarding - update address clicked",
  "SC - Onboarding - Persona Verification: Impression":
    "cash card onboarding - persona verify clicked",
  "SC - Onboarding - Redirected to Persona":
    "cash card onboarding - redirected to persona",
  "CBL - Not Available - Screen: Impression": "cbs: no offers page: Impression",
  "Unsecured Personal Loan - View Statement: Tap":
    "Unsecured Loan: view statement",
  "Promos - Closure Promo Applied - Return to Dashboard: Tap":
    "closure promo applied view",
  "Dashboard - First Month Discount Promo Card - Claim Offer: Tap":
    "First Month Discount Promo Card - Claim Offer: Tap",
  "Rent Reporting - Premium Benefits - Card: Impression":
    "Rent Reporting - Premium Benefits - Also Includes Card: Impression",
  "Rent Reporting - Manual Selection View: Impression":
    "rent reporting manual detection home: impression: Impression",
  "Rent Reporting - Manual Selection View - CTA: Tap":
    "rent reporting manual detection home: tap: Tap",
  "Rent Reporting - Manual Selection - Continue Button: Tap":
    "rent reporting manual selection: continue: Tap",
  "Rent Reporting - Manual Selection - Add Bank Account Button: Tap":
    "rent reporting manual selection: add bank account: Tap",
  "Rent Reporting - Manual Selection - Select Transaction: Tap":
    "rent reporting manual selection: select transaction: Tap",
  "Bill Reporting - Onboarding - Connect Bank Account Page: Impression":
    "bill reporting onboarding - Connect Bank Account Page: Impression",
  "Bill Reporting - Onboarding - Connect Bank Account: Tap":
    "bill reporting onboarding - Connect Bank Account: Tap",
  "Bill Reporting - Onboarding - Bank Account Success":
    "bill reporting onboarding - Bank Account Success: Tap",
  "Bank Link Error": "bill or rent reporting: link bank error",
  "Rent Reporting - Select Backreporting Option":
    "rent reporting: select backreporting option",
  "Rent Reporting - Onboarding - Add Bank Account Button: Tap":
    "rent reporting onboarding: add bank account when no transactions found: Tap",
  "Store - Exit: Tap": "shop: clicked close",
  "CA - Activation - Activate and Purchase Button: Tap":
    "credit line: clicked activate",
  "CA - Activation - Agree to Terms: Tap":
    "credit line: clicked agree to terms",
  "CA - Activation - I Understand: Tap": "credit line: clicked understand",
  "CA - Payment - Basic Autopay Discount - Turn On Autopay Button: Tap":
    "basic autopay discount: enable autopay",
  "CA - Payment - Basic Autopay Discount - Maybe Later Button: Tap":
    "basic autopay discount: maybe later",
  "CBL - Immediate Upsell - Maybe Later Button: Tap":
    "credit builder loan immediate upsell: maybe later",
  "CBL - Immediate Upsell: Impression":
    "credit builder loan immediate upsell: impression",
  "CBL - Immediate Upsell - Add Payment Method Button: Tap":
    "credit line: clicked not ready",
  "CBL - Immediate Upsell - Learn More Button: Tap":
    "credit builder loan immediate upsell: learn more",
  "CA - Plan Details - Credit Increase Congrats - Continue Button: Tap":
    "credit line: continue from congratulations",
  "Onboarding - Express Plan Capacity Full Screen: Impression":
    "onboarding: express plan capacity full page impression: Impression",
  "Mobile Adoption View - Continue Button: Tap":
    "mobile adoption view: continue",
  "Mobile Adoption View: Impression": "mobile adoption view: impression",
  "Persona - Intro Screen Close Button: Tap": "persona: closed intro screen",
  "Personalized Onboarding - Get Started Button: Tap":
    "Get started personalized onboarding: clicked",
  "Promos - Email Incentive Promo - CTA: Tap": "promo view: clicked CTA button",
  "Identity Reverification - Edit Address Button: Tap":
    "Identity Reverification - Edit Address Button",
  "Identity Reverification - Edit Phone Button: Tap":
    "Identity Reverification - Edit Phone Button",
  "Identity Reverification - Back Home Button: Tap":
    "Identity Reverification - Back Home Button",
  "CA - Activation - Select Another Item: Tap": "Select another item clicked",
  "Store - Select Item: Tap": "shop: item selected",
  "CA - Payment - Setup Repayment - Return To Dashboard: Tap":
    "credit line: clicked not ready",
  "CA - Activation - Get Started Button: Tap":
    "streamlined education view: clicked ",
  "CA - Activation - Restart Screen: Impression":
    "streamlined education view: impression",
  "CA - Payment - Select Scheduled Payment Date: Tap":
    "schedule payment selector: date selected",
  "CA - Payment - Schedule Payment Select Button: Tap":
    "schedule payment selector: clicked button",
  "CA - Payment - Screen: Impression": "streamlined payment view: impression",
  "CA - Payment - Submit Payment Button: Tap":
    "streamlined payment view: clicked ",
  "CA - Payment - Payment Success": "streamlined payment view: payment success",
  "CA - Payment - Restart Payment - Success": "CA Restart Payment - Success",
  // "CA - Activation - Restart Screen: Impression":
  //   "ultimate education view: impression",
  "Store - Order Confirmation - User Has No Orders":
    "order confirmation view: user has no orders",
  "Store - Order Confirmation - Order Not Found":
    "order confirmation view: order not found",
  "Subscription Cancellation - Satisfaction Survey: Tap":
    "Subscription Cancellation - Satisfaction Survey",
  "Tax Filing - Filing Option - Continue: Tap":
    "tax filing: filing option - clicked continue",
  "Tax Filing - Filing Option - Maybe Later: Tap":
    "tax filing: filing option - clicked maybe later",
  "Tax Filing - Start Filing Card - File Now: Tap":
    "tax filing: start filing card - clicked file now",
  "Tax Filing - Continue Card: Tap": "tax filing: continue card - clicked",
  "Tax Filing - Status Card: Tap": "tax filing: status card - clicked",
  "Tax Filing - Upgrade Success And Redirect To Column Tax":
    "tax filing: upgrade success and redirect to Column Tax",
  "Tax Filing - Takeover - File Now Button: Tap":
    "tax filing: takeover - clicked file now",
  "Tax Filing - Takeover - Exit: Tap": "tax filing: takeover - dismissed",
  "Dashboard - Partial Payment Upsell: Impression":
    "Partial Payment Upsell shown: Impression",
  "Dashboard - One Month Forgiveness Upsell: Impression":
    "One Month Forgiveness Upsell shown: Impression",
  "Dashboard - Fifty Percent Forgivessness Upsell: Impression":
    "Fifty Percent Forgivessness Upsell shown: Impression",
  "Rent Reporting - Upsell Card: Impression":
    "rent reporting upsell: card impression",
  "Rent Reporting - Upsell Card - CTA: Tap": "Rent Reporting Upsell - CTA: Tap",
  "Hello Privacy - Dashboard Card - Go to Details: Tap":
    "Hello Privacy - go to details page: Tap",
  "Hello Privacy - Dashboard Card - Take Action Button: Tap":
    "Hello Privacy - dashboard card clicked: Tap",
  "Hello Privacy - Dashboard Card - Maybe Later Button: Tap":
    "Hello Privacy - maybe later clicked: Tap",
  "Unsecured Personal Loan - Dashboard Card CTA":
    "Unsecured Personal Loan - dashboard card clicked",
  "Dashboard - Plan Details Card - Button: Tap": "Plan Details Card - Button",
  "Dashboard - Plan Details Card: Impression": "Plan Details Card: Impression",
  "Dashboard - SC Upsell Card: Impression":
    "secured card upsell: card impression: Impression",
  "Dashboard - SC Offer - CTA: Tap": "Home Dashboard - Secured Card Offer: CTA",
  "Dashboard - SC Offer - Continue: Tap":
    "Home Dashboard - Secured Card Offer: Continue",
  "Dashboard - Offer Card CTA: Tap": "offer: clicked cta",
  "Dashboard - Offer Card: Impression": "Offer Card: Impression",
  "Dashboard - Reports Available Card: Impression":
    "Reports Available Card: Impression",
  "Dashboard - Reports Available Card CTA: Tap":
    "Reports Available Card - CTA Button: Tap",
  "Dashboard - Community Card: Impression": "community: entrypoint impression",
  "Dashboard - Community Card CTA: Tap": "community: entrypoint click",
  "Dashboard - Marketplace Card - Insurify: Impression":
    "Marketplace Card - Insurify: Impression",
  "Dashboard - Marketplace Card - Insurify View Offer: Tap":
    "Marketplace Card - Insurify: Tap",
  "Demo Mode - Start Tour Modal - Cancel: Tap":
    "refer: canceled demo mode tour start from demo page",
  "Demo Mode - Start Tour Modal - Start Demo: Tap":
    "refer: started demo mode from initial popup on demo page",
  "Demo Mode - Tour - Redirected to Onboarding Signup Screen":
    "refer: redirecting to signup onboarding page from demo mode",
  "Demo Mode - Tour - Cancel":
    "refer: closed demo mode while tour was not finished",
  "Demo Mode - Tour - Close at Last Step: Tap":
    "refer: demo tour, made it to the last step and closed",
  "Auth - Forgot Login - Finish Updating Button: Tap":
    "forgot login: finished updating",
  "Auth - Forgot Login - Persona Button: Tap":
    "forgot login: persona button click",
  "Auth - Forgot Login - Persona Passed": "forgot login: persona passed",
  "Auth - Reset Password Button: Tap": "auth: submitted password reset",
  "Auth - Forgot Password Button": "auth: clicked forgot password",
  "Auth - Forgot Email Button": "auth: clicked forgot email",
  "Onboarding - Credit Freeze - Retry Button: Tap":
    "credit-freeze: click retry",
  "Onboarding - Duplicate Account - Reset Button: Tap":
    "duplicate account: clicked reset",
  "Onboarding - Education - CTA: Tap": "onboarding education: click cta button",
  "Onboarding - Education - Screen: Impression":
    "onboarding education: impression",
  "Onboarding - Manual Upload Persona - Continue Button: Tap":
    "manual-upload page: clicked continue",
  "Onboarding - IDV - Started": "onboarding: idv start",
  "Onboarding - IDV - Finished": "onboarding: idv finish",
  "Onboarding - IDV - Risk Checking Timed Out":
    "error: risk checking timed out",
  "Onboarding - Submit DOB Button: Tap": "onboarding: submit dob",
  "Onboarding - Submit Review Button: Tap": "onboarding: submit review",
  "Onboarding - Submit Name Button: Tap": "onboarding: submit name",
  "Onboarding - Submit SSN Button: Tap": "onboarding: submit ssn",
  "Onboarding - Create Account": "freemium: create account",
  "Onboarding - Create Account - Email Taken": "error: email taken",
  "Onboarding - Create Account - Intro Promo: Impression":
    "Create Account - Intro Promo - Special offer activated: Impression",
  "Onboarding - Signup Screen: Impression": "onboarding: impression",
  "Onboarding - Signup Screen - Email Pre-Filled": "signup: email pre-filled",
  "Onboarding - Sign Up Button: Tap": "onboarding: clicked join kikoff",
  "Onboarding - Authenticated User": "onboarding: authenticated user",
  "Auth - Logged In": "auth: log in",
  "Onboarding - Created User": "onboarding: created user",
  "Onboarding - Submit Address Button: Tap": "onboarding: submit name",
  "Onboarding - Address Suggestion Failed":
    "onboarding: address suggestion failed",
  "Onboarding - Submit Phone Code Button: Tap": "onboarding: submit phone code",
  "Onboarding - Submit Phone Button: Tap": "onboarding: submit phone number",
  "Onboarding - Underage Waitlist Screen: Impression":
    "onboarding: view underage waitlist",
  "Onboarding - State Waitlist Screen: Impression":
    "onboarding: view state waitlist",
  "Bill Reporting - Onboarding - No Transactions Found":
    "bill reporting onboarding - No Transactions Found",
  "Bill Reporting - Onboarding - Some Transactions Found":
    "bill reporting onboarding - Some Transactions Found",
  "Bill Reporting - Onboarding - No Transactions View: Impression":
    "bill reporting onboarding - No Transactions View",
  "CBL - Withdraw Voucher Funds - Withdraw Funds Success":
    "cbs: withdraw voucher funds success",
  "Credit Score - Data Loaded": "credit: load data",
  "CA - Payment - Payment Error": "credit line: payment error",
  "CA - Payment - Retry Payment": "credit line: retry payment",
  "CA - Activation - Activate": "credit line: clicked activate",
  "CA - Activation - Credit Line Opened":
    "streamlined payment view: shop checkout",
  "Debt Relief - Created Bucket": "debt relief: created bucket",
  "Hello Privacy - ID Theft Insurance - User Enrolled In IDP":
    "Hello Privacy ID Theft Insurance - user enrolled in IDP: Tap",
  "CA - Subscription - Update Auto-Renewal Success":
    "credit line: closed upfront payment account",
  "Auth - Logged Out": "auth: log out",
  "Auth - Impersonation End": "auth: impersonation_end",
  "Stripe - Failed": "error: stripe failed once",
  "Stripe - Failed to Load": "error: stripe failed to load",
  "Get Help - Call Our Team: Tap": "Get Help - Call Our Team Click: Tap",
  "Get Help - Live Chat: Tap": "Get Help - Live Chat Click: Tap",
  "CBL - Close - United Account Closure Flow Entered: Tap":
    "United Account Closure - cbl - flow entered: Tap",
  "SC - Close - United Account Closure Flow Entered: Tap":
    "United Account Closure - sc - flow entered: Tap",
  "CA - Close - United Account Closure Flow Entered: Tap":
    "United Account Closure - ca - flow entered: Tap",
  "Credit Score - Tax: Impression": "tax: action impression",
  "Credit Score - Disputes: Impression": "disputes: action impression",
  "Credit Score - Debt Relief: Impression": "debt relief: action impression",
  "Credit Score - Rent Reporting: Impression":
    "rent reporting: action impression",
  "Credit Score - CBL: Impression": "cbs: action impression",
  "Credit Score - Factors - Payment History: Tap":
    "factors: visited payment history",
  "Credit Score - Factors - Credit Age: Tap": "factors: visited credit age",
  "Credit Score - Factors - Derogatory Marks: Tap":
    "factors: visited derogatory marks",
  "Credit Score - Factors - Credit Mix: Tap": "factors: visited credit mix",
  "Credit Score - Factors - Payment History Diff: Tap":
    "factors: visited payment history diff",
  "Credit Score - Factors - Credit Age Diff: Tap":
    "factors: visited credit age diff",
  "Credit Score - Factors - Derogatory Marks Diff: Tap":
    "factors: visited derogatory marks diff",
  "Credit Score - Factors - Credit Mix Diff: Tap":
    "factors: visited credit mix diff",
  "Credit Score - Factors - Payment History: Impression":
    "credit_diff factor view: payment history",
  "Credit Score - Factors - Credit Age: Impression":
    "credit_diff factor view: credit age",
  "Credit Score - Factors - Derogatory Marks: Impression":
    "credit_diff factor view: derogatory marks",
  "Credit Score - Factors - Credit Mix: Impression":
    "credit_diff factor view: credit mix",
  "Credit Score - Factors - Payment History Diff: Impression":
    "credit_diff factor view: payment history diff",
  "Credit Score - Factors - Credit Age Diff: Impression":
    "credit_diff factor view: credit age diff",
  "Credit Score - Factors - Derogatory Marks Diff: Impression":
    "credit_diff factor view: derogatory marks diff",
  "Credit Score - Factors - Credit Mix Diff: Impression":
    "credit_diff factor view: credit mix diff",
  "Credit Score - Full Report - Consumer Statements: Impression":
    "Full Report - Consumer Statements: Impression",
  "Credit Score - Full Report - Addresses: Impression":
    "Full Report - Addresses: Impression",
  "Credit Score - Full Report - Personal Info: Impression":
    "Full Report - Personal Info: Impression",
  "Credit Score - Full Report - Overview: Impression":
    "Full Report - Overview: Impression",
  "Credit Score - Transunion Tab: Tap":
    "credit: user clicked bureau tab - transunion: Tap",
  "Credit Score - Experian Tab: Tap":
    "credit: user clicked bureau tab - experian: Tap",
  "Hello Privacy - Privacy Protection Details - Show Data Removed Button: Tap":
    "Hello Privacy - Data Removed tab clicked: tap",
  "Hello Privacy - Privacy Protection Details - Show Data Pending Button: Tap":
    "Hello Privacy - Data Pending tab clicked: tap",
  "Hello Privacy - Privacy Protection Details - Show Data Exposed Button: Tap":
    "Hello Privacy - Data Exposed tab clicked: tap",
  "Promos - Email Incentive Promo - Dropoff Incentives First Month Half Off":
    "promo view: dropoff_incentives_first_month_half_off",
  "Promos - Email Incentive Promo - Last Month Free":
    "promo view: last_month_free",
  "CA - Renewal - Select Plan: Tap": "renewal: plan selected",
  "CA - Activation - Select Plan: Tap": "onboarding: plan selected",
  "Debt Consolidation - Select Goal Build":
    "debt consolidation: clicked goal build",
  "Debt Consolidation - Select Goal Borrow":
    "debt consolidation: clicked goal borrow",
  "Debt Consolidation - Select Plan Basic":
    "debt consolidation: clicked plan basic",
  "Debt Consolidation - Select Plan Premium":
    "debt consolidation: clicked plan premium",
  // "Hello Privacy - Dashboard Card: Impression":
  //   "Hello Privacy - ultimate - dashboard card displayed: Impression",
  "Hello Privacy - Dashboard Card: Impression":
    "Hello Privacy - non-ultimate - dashboard card displayed: Impression",
};
