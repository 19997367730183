import React from "react";
import { FluentResource } from "@fluent/bundle";

import KButton from "@kikoff/components/src/v1/buttons/KButton";
import { combineClasses } from "@kikoff/utils/src/string";

import { track } from "@util/analytics";
import { newReactLocalization } from "@util/l10n";

import styles from "./styles.module.scss";

const RESOURCES = {
  en: new FluentResource(`landing-open-acct-in-mins = Open your account in minutes!
landing-get-started = Get started
landing-join-today = Join Kikoff today
landing-your-email-placeholder = Your email
sign-up = Sign up
build-credit-fast = Build credit fast
`),
  es: new FluentResource(`landing-open-acct-in-mins = ¡Abre tu cuenta en minutos!
landing-get-started = Empezar
landing-join-today = Únete a Kikoff hoy
landing-your-email-placeholder = Tu correo electrónico
sign-up = Inscribirse
build-credit-fast = Crea crédito rápido
`),
};

interface JoinProps {
  className?: string;
}

const Join: React.FC<JoinProps> = ({ className }) => {
  const l10n = newReactLocalization(RESOURCES);

  return (
    <section
      className={combineClasses(styles["sticky-container"], className)}
      data-layout-sticky
    >
      <a
        className={styles["floating-button"]}
        href="/onboarding"
        onClick={() => {
          track.tap("Landing - Signup - Sticky Signup Bar");
        }}
      >
        <div className={styles["texts-wrapper"]}>
          <div className="text:heading-4 color:strong-on-inverse">Kikoff</div>
          <div className="text:regular+ color:strong-on-inverse">
            {l10n.getString("build-credit-fast")}
          </div>
        </div>
        <div>
          <KButton size="small" variant="container">
            <span className="color:primary text:large+">
              {l10n.getString("sign-up")}
            </span>
          </KButton>
        </div>
      </a>
    </section>
  );
};

export default Join;
