import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { web } from "@kikoff/proto/src/protos";
import { webRPC } from "@kikoff/proto/src/rpc";
import { handleFailedStatus, handleProtoStatus } from "@kikoff/utils/src/proto";

import { RootState } from "@store";

import { createLoadableSelector, thunk } from "../utils";

const initialState = {
  surveyResponse: null as CreditGoalsSurvey,
};

export type PersonalizationState = typeof initialState;

const personalizationSlice = createSlice({
  name: "personalization",
  initialState,
  reducers: {
    updateSurveyResponse(
      state,
      { payload }: PayloadAction<PersonalizationState["surveyResponse"]>
    ) {
      state.surveyResponse = payload;
    },
  },
});

const { actions } = personalizationSlice;
export const {} = actions;
export default personalizationSlice.reducer;

export const selectCreditGoalsSurvey = createLoadableSelector(
  () => (state: RootState) => state.personalization.surveyResponse,
  {
    loadAction: () => fetchCreditGoalsSurvey(),
  }
);

export const submitCreditGoalsSurvey = (survey: CreditGoalsSurvey) =>
  thunk((dispatch) =>
    webRPC.Personalization.submitCreditGoalsSurvey(survey).then(
      handleProtoStatus({
        SUCCESS() {
          dispatch(fetchCreditGoalsSurvey());
        },
        _DEFAULT: handleFailedStatus("Failed to submit credit goals survey"),
      })
    )
  );

export const fetchCreditGoalsSurvey = () =>
  thunk((dispatch) =>
    webRPC.Personalization.getCreditGoalsSurvey({}).then(
      handleProtoStatus({
        SUCCESS(survey) {
          dispatch(actions.updateSurveyResponse(survey));
        },
        _DEFAULT: handleFailedStatus("Failed to fetch credit goals survey"),
      })
    )
  );

export type CreditGoalsSurvey = web.public_.ISubmitCreditGoalsSurveyRequest;

export namespace CreditGoalsSurvey {
  export type CreditGoal = keyof typeof web.public_.CreditGoal;
}
